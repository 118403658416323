import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "./styles/App.css";

import ProtectedRoute from "./components/Common/ProtectedRoute";
import TheCup from "./components/Competition/TheCup";
import Viper from "./components/Competition/Viper";
import CompetitionPage from "./pages/Competition";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Login";
import MainLayout from "./pages/MainLayout";
import ReportPage from "./pages/Report";

import { ReportActionProvider } from "./context/ReportActionContext";
import useTheme from "./hooks/useTheme";
import { useAppDispatch } from "./redux/hooks";
import { loadAuthInfo } from "./redux/slices/authSlice";
import Profile from "./pages/Profile";
import ComingSoon from "./pages/ComingSoon";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useTheme();

  useEffect(() => {
    dispatch(loadAuthInfo());
  }, [dispatch]);

  const setFullHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  window.addEventListener("resize", setFullHeight);
  setFullHeight();

  const routes = [
    { path: "/login", element: <LoginPage /> },
    { path: "/", element: <HomePage /> },
    { path: "/curator", element: <HomePage /> },
    { path: "/report", element: <ReportPage /> },
    { path: "/competitions", element: <CompetitionPage /> },
    { path: "/competitions/viper", element: <Viper /> },
    { path: "/competitions/thecup", element: <TheCup /> },
    { path: "/profile", element: <Profile /> },
    { path: "/leaderboard", element: <ComingSoon /> },
    { path: "/search", element: <ComingSoon /> },
    { path: "/pay", element: <ComingSoon /> },
  ];

  return (
    <ReportActionProvider>
      <Router>
        <MainLayout>
          <Routes>
            {routes.map(({ path, element }) => (
              <Route
                key={path}
                path={path}
                element={
                  path !== "/login" ? (
                    <ProtectedRoute>{element}</ProtectedRoute>
                  ) : (
                    element
                  )
                }
              />
            ))}
          </Routes>
        </MainLayout>
      </Router>
    </ReportActionProvider>
  );
};

export default App;
