import classNames from "classnames";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { useReportActionContext } from "../../context/ReportActionContext";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setCurrentReportFilterCategory,
  setMenuOpened,
  setReportFilterOpened,
  setReportViewOpened,
} from "../../redux/slices/globalSlice";
import {
  clearFilter as clearReportFilter,
  updateSelectedDateType as updateReportSelectedDateType,
  updateSelectedDateRange as updateReportSelectedDateRange,
} from "../../redux/slices/reportSlice";

import {
  clearFilter as clearProfileFilter,
  updateSelectedDateType as updateProfileSelectedDateType,
  updateSelectedDateRange as updateProfileSelectedDateRange,
} from "../../redux/slices/profileSlice";

import { getDateType } from "../../utils/date";

import { CompetitionIndex } from "../../const/competition";
import { FavoriteType } from "../../const/enums/FavoriteType";
import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { SelectedDateType } from "../../const/enums/SelectedDateType";

import IconButton from "../../components/Common/IconButton";
import IconToggle from "../../components/Common/IconToggle";
import ArrowBackIcon from "../../components/icons/ArrowBack";
import CalenderIcon from "../../components/icons/CalenderIcon";
import EyeIcon from "../../components/icons/EyeIcon";
import FilterResetIcon from "../../components/icons/FilterResetIcon";
import MenuIcon from "../../components/icons/MenuIcon";
import StarIcon from "../../components/icons/StarIcon";
import StarIconEmpty from "../../components/icons/StarIconEmpty";
import SelectThemeButton from "../../components/Theme/SelectThemeButton";

interface HeaderProps {
  openThemeSelectPopup: () => void;
}

const Header = ({ openThemeSelectPopup }: HeaderProps) => {
  const { onHandleToggleFavorite } = useReportActionContext();

  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage, previousPage, isMenuOpened } = useAppSelector(
    (state) => state.global
  );
  const {
    currentReport,
    selectedDateType: reportSelectedDateType,
    appliedDateType: reportAppliedDateType,
    selectedFilters: reportSelectedFilters,
    favorites,
    reportFilterViews,
  } = useAppSelector((state) => state.report);

  const {
    selectedDateType: profileSelectedDateType,
    appliedDateType: profileAppliedDateType,
    selectedFilters: profileSelectedFilters,
  } = useAppSelector((state) => state.profile);

  const selectedDateType =
    currentPage === PageType.Profile
      ? profileSelectedDateType
      : reportSelectedDateType;
  const appliedDateType =
    currentPage === PageType.Profile
      ? profileAppliedDateType
      : reportAppliedDateType;
  const selectedFilters =
    currentPage === PageType.Profile
      ? profileSelectedFilters
      : reportSelectedFilters;

  const [headerTitle, setHeaderTitle] = useState<string>(PageType.Home);

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const toggleMenu = () => {
    dispatch(setMenuOpened(!isMenuOpened));
    if (!isMenuOpened) {
      dispatch(setCurrentPage(PageType.SideMenu));
    }
  };

  const handleBack = () => {
    switch (currentPage) {
      case PageType.Report:
        navigator("/");
        break;
      case PageType.SideMenu:
        dispatch(setMenuOpened(false));
        dispatch(setCurrentPage(previousPage));
        break;
      case PageType.ReportFilter:
        dispatch(setCurrentPage(previousPage));
        handleFilterBack();
        break;

      case PageType.ReportViewList:
        dispatch(setCurrentPage(PageType.Report));
        dispatch(setReportViewOpened(false));
        break;

      case PageType.ReportViewSave:
        dispatch(setCurrentPage(PageType.ReportFilter));
        dispatch(setReportViewOpened(false));
        break;
      case PageType.Profile:
        dispatch(clearProfileFilter());
        dispatch(setCurrentPage(PageType.Home));
        navigator("/");
        break;
      default:
        break;
    }
  };

  const handleFavoriteToggle = () => {
    onHandleToggleFavorite(() => {
      toast.success(
        !isFavorite ? "Added to favorite list" : "Removed from favorite list"
      );
    });
  };

  const handleFilterBack = () => {
    const dateFilter = selectedFilters["Date"];
    if (!dateFilter) return;
    if (currentPage === PageType.Report) {
      dispatch(updateReportSelectedDateType(appliedDateType));
      dispatch(updateReportSelectedDateRange(dateFilter));
    } else if (currentPage === PageType.Profile) {
      dispatch(updateProfileSelectedDateType(appliedDateType));
      dispatch(updateProfileSelectedDateRange(dateFilter));
    }
  };

  const handleResetFilter = () => {
    if (currentPage === PageType.Report) {
      dispatch(clearReportFilter());
    } else if (currentPage === PageType.Profile) {
      dispatch(clearProfileFilter());
    }
  };

  useEffect(() => {
    if (currentPage === PageType.Report) {
      setHeaderTitle(currentReport?.reportName || "");
    } else {
      setHeaderTitle(currentPage);
    }
  }, [currentPage, currentReport]);

  useEffect(() => {
    const checkFavorite = (type: FavoriteType, id: number) =>
      favorites.some(
        (favorite) =>
          favorite.favoriteType === type && favorite.favoriteId === id
      );

    if (currentPage === PageType.Report) {
      if (!currentReport?.id) return;
      setIsFavorite(checkFavorite(FavoriteType.Report, currentReport?.id));
    } else if (
      [PageType.CompetitionViper, PageType.CompetitionTheCup].includes(
        currentPage
      )
    ) {
      const competitionId = CompetitionIndex[currentPage];
      setIsFavorite(checkFavorite(FavoriteType.Competition, competitionId));
    } else if (currentPage === PageType.Profile) {
      setIsFavorite(checkFavorite(FavoriteType.Profile, 0));
    }
  }, [currentPage, currentReport, favorites]);

  const renderMenuIcon = () => {
    if (
      currentPage === PageType.Report ||
      currentPage === PageType.Home ||
      currentPage === PageType.Competition ||
      currentPage === PageType.CompetitionViper ||
      currentPage === PageType.CompetitionTheCup ||
      currentPage === PageType.Leaderboard ||
      currentPage === PageType.Pay ||
      currentPage === PageType.Search
    ) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={toggleMenu}
          className="hover:bg-transparent"
        >
          <MenuIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    } else if (
      currentPage === PageType.SideMenu ||
      currentPage === PageType.ReportFilter ||
      currentPage === PageType.ReportViewList ||
      currentPage === PageType.ReportViewSave ||
      currentPage === PageType.Profile
    ) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={handleBack}
          className="hover:bg-transparent"
        >
          <ArrowBackIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    }
  };

  const renderFavoriteIcon = () => {
    return (
      (currentPage === PageType.Report ||
        currentPage === PageType.CompetitionViper ||
        currentPage === PageType.CompetitionTheCup ||
        currentPage === PageType.Profile) && (
        <IconToggle
          value={isFavorite}
          onValueChange={handleFavoriteToggle}
          iconOn={<StarIcon className="text-primary" />}
          iconOff={<StarIconEmpty className="text-primaryText" />}
        ></IconToggle>
      )
    );
  };

  const renderHeaderAction = () => {
    if (currentPage === PageType.ReportFilter) {
      return (
        <div className="flex items-center py-s gap-s">
          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent"
            onClick={handleResetFilter}
          >
            <FilterResetIcon className="text-primaryText active:text-primary" />
          </IconButton>
        </div>
      );
    } else if (currentPage === PageType.ReportViewList) {
      return (
        <div className="flex items-center py-s gap-s">
          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent relative"
            onClick={() => {}}
          >
            <EyeIcon className="text-primaryText active:text-primary w-[28px] h-[28px]" />

            <div className="absolute right-[0px] bottom-[-4px] w-[12px] h-[12px] bg-primary flex items-center justify-center rounded-xl">
              <span className="text-base-highlight text-center font-primary text-[8px] not-italic font-medium leading-[146%]">
                {reportFilterViews.length}
              </span>
            </div>
          </IconButton>
        </div>
      );
    } else if (
      currentPage === PageType.Report ||
      currentPage === PageType.Profile
    ) {
      if (!selectedFilters["Date"] || currentReport?.reportType === "tableau")
        return "";

      return (
        <div
          className="flex items-center py-s gap-s text-primaryText active:text-primary cursor-pointer"
          onClick={() => {
            dispatch(setCurrentPage(PageType.ReportFilter));
            dispatch(setCurrentReportFilterCategory(ReportFilterCategory.Date));
            dispatch(setReportFilterOpened(true));
          }}
        >
          <div className="flex flex-col items-start">
            {selectedDateType === SelectedDateType.Custom ? (
              <>
                <h2 className="font-noto text-xs not-italic font-normal leading-[100%] whitespace-nowrap">
                  {selectedFilters["Date"][0]}
                </h2>
                <h2 className="font-noto text-xs not-italic font-normal leading-[100%] whitespace-nowrap">
                  {selectedFilters["Date"][1]}
                </h2>
              </>
            ) : (
              getDateType(
                selectedFilters["Date"][0],
                selectedFilters["Date"][1]
              )
            )}
          </div>

          <CalenderIcon className="" />
        </div>
      );
    }
  };

  const isVisibleHeaderAction = () => {
    return (
      currentPage === PageType.Report ||
      currentPage === PageType.ReportFilter ||
      currentPage === PageType.ReportViewList ||
      currentPage === PageType.ReportViewSave ||
      currentPage === PageType.Profile
    );
  };

  const renderTitle = () => {
    return (
      currentPage !== PageType.SideMenu && (
        <div
          className={classNames(
            "font-noto not-italic whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[160px] md:max-w-[240px]",
            {
              "text-2xl font-bold leading-[120%]":
                headerTitle === PageType.Home,
              "text-m font-semibold leading-[120%]":
                headerTitle !== PageType.Home,
              "max-w-[160px]": !isVisibleHeaderAction(),
              "max-w-[240px]": currentReport?.reportType === "tableau",
            }
          )}
        >
          {headerTitle}
        </div>
      )
    );
  };

  const renderSeparator = () => {
    return (
      currentPage !== PageType.SideMenu &&
      currentPage !== PageType.ReportFilter && (
        <div className="w-px h-[21px] flex-shrink-0 bg-neutral-900"></div>
      )
    );
  };

  const renderThemeButton = () => {
    return (
      (currentPage === PageType.Home ||
        currentPage === PageType.SideMenu ||
        currentPage === PageType.Profile) && (
        <SelectThemeButton openSelectTheme={openThemeSelectPopup} />
      )
    );
  };

  return (
    <header className="flex p-[12px] h-[52px] justify-between items-center bg-opposite z-40">
      <div className="flex gap-2 items-center">
        {renderMenuIcon()}
        {renderSeparator()}
        {renderFavoriteIcon()}
        {renderTitle()}
      </div>

      <div className="flex gap-2 items-center">
        {renderThemeButton()}
        {renderHeaderAction()}
      </div>
    </header>
  );
};

export default Header;
