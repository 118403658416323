import classNames from "classnames";
import flatpickr from "flatpickr";
import isMobile from "is-mobile";
import { useEffect, useState } from "react";

import {
  CategoricalFilter,
  Dashboard,
  DataValue,
  FilterUpdateType,
  Parameter,
  TableauViz,
} from "@tableau/embedding-api";

import ArrowLeftBack from "../../icons/ArrowBack";
import TableauVizFilterDropdown from "./TableauVizFilterDropdown";
import TableauVizParameterDropdown from "./TableauVizParameterDropdown";

import { formatDateToISO } from "../../../utils/date";

import {
  DATE_RANGE_MAPPINGS,
  TABLEAU_DATE_FILTER,
  TABLEAU_FILTERS,
  TABLEAU_PARAMETERS,
} from "./tableauCustomData";

import "flatpickr/dist/flatpickr.min.css";
import "./index.scss";

interface NewFilter {
  field: string;
  title: string;
  category: string;
  options: {
    value: any;
    formattedValue: string | undefined;
    checked: boolean;
  }[];
}

type SelectedItem = {
  value: any; // Adjust the type according to your specific use case
};

const TableauFilter = ({
  viz,
  reportAlias,
}: {
  viz: TableauViz;
  reportAlias: string;
}) => {
  const categories = [
    { title: "Dates", category: "date" },
    { title: "Hierarchy", category: "hierarchy" },
    { title: "Additional", category: "additional" },
  ];
  const relativeDateOptions = [
    "Today",
    "Yesterday",
    "YTD",
    "Last Week",
    "Last Month",
    "MTD",
    "Last Quarter",
    "Last Year",
  ];
  const [filters, setFilters] = useState<any>({});
  const [parameters, setParameters] = useState<any>({});
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [parametersLoaded, setParametersLoaded] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState("date");
  const [selectedDateOption, setSelectedDateOption] = useState("Today");
  const [dateRangeSelected, setDateRangeSelected] = useState(false);

  useEffect(() => {
    initializeFlatPicker();
  }, [selectedFilterCategory]);

  useEffect(() => {
    if (selectedDates.length === 2) {
      applyDateFilter(selectedDates);

      let inputElement = document.getElementById(
        "datePicker"
      ) as HTMLInputElement | null;

      if (!inputElement) return;

      const formattedDateRange = `${formatDateToISO(
        selectedDates[0]
      )} ~ ${formatDateToISO(selectedDates[1])}`;

      inputElement.value = formattedDateRange;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates]);

  useEffect(() => {
    initFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKeys]);

  const initializeFlatPicker = () => {
    if (flatpickr) {
      const input = document.querySelector("#datePicker");

      if (input) {
        flatpickr(input, {
          mode: "range",
          onChange: (dates) => {
            // Ensure two dates (start and end) are selected
            if (dates.length === 2) {
              // Set the selectedDates state with the Date objects directly
              setSelectedDates(dates);

              setDateRangeSelected(true);
            }
          },
        });
      }
    }
  };

  const initFilters = async (): Promise<void> => {
    try {
      console.log("Initializing filters");

      const sheet = viz.workbook.activeSheet as Dashboard;
      const worksheet = sheet.worksheets.find((ws) => ws.name === "Filters");

      const newFilters: Record<string, NewFilter> = {};
      const allFilters: Record<string, DataValue[]> = {};

      if (!worksheet) return;

      const filterData = await worksheet.getFiltersAsync();

      for (const filter of filterData) {
        if (filter.filterType !== "categorical") continue;

        const field = filter.fieldName;
        const key = field.replace(/ /g, "_").toLowerCase();

        const tableauFilters = TABLEAU_FILTERS[reportAlias];

        // Skip processing if the field is not defined in the Tableau filters
        if (!tableauFilters) return;

        // Skip processing if the field is not visible
        if (!tableauFilters.hasOwnProperty(key)) continue;

        let appliedFilterValues = (filter as CategoricalFilter).appliedValues;

        // Only fetch domain values if necessary
        if (!appliedFilterValues?.length) {
          const domain = await (filter as CategoricalFilter).getDomainAsync();
          appliedFilterValues = domain.values;
        }

        // Use existing filters if available and active
        const activeKey = activeKeys.includes(key);
        allFilters[key] =
          !filters[key] || !activeKey
            ? appliedFilterValues.filter((x) => x.value !== null)
            : [...filters[key].options];

        newFilters[key] = {
          field,
          title: tableauFilters[key].title,
          category: tableauFilters[key].category,
          options: allFilters[key].map((value) => ({
            value: value.value,
            formattedValue: value.formattedValue,
            checked: !!appliedFilterValues.find((v) => v.value === value.value),
          })),
        };
      }

      setFilters(newFilters);
      setFiltersLoaded(true);
      initParams();
    } catch (error) {
      console.error("Error initializing filters:", error);
      setFiltersLoaded(false);
    }
  };

  const initParams = () => {
    // Define the types for newParameters and visibleParameters
    const newParameters: Record<
      string,
      {
        field: string;
        title: string;
        category: string;
        options: { value: any; formattedValue: string; checked: boolean }[];
      }
    > = {};

    viz.workbook.getParametersAsync().then((parametersData) => {
      const allParameters: Record<string, DataValue[]> = {};

      parametersData.forEach((parameter) => {
        const field = parameter.name;
        let key = field.replace(/ /g, "_").toLowerCase();
        key = key.replace(/\//g, "_").toLowerCase();
        const allowableValues = parameter.allowableValues.allowableValues;
        const currentValue = parameter.currentValue;

        if (!allowableValues || allowableValues.length === 0) return;

        allParameters[key] = allowableValues.filter((x) => x.value !== null);

        const tableauParams = TABLEAU_PARAMETERS[reportAlias];

        if (tableauParams.hasOwnProperty(key)) {
          newParameters[key] = {
            field,
            title: tableauParams[key].title,
            category: tableauParams[key].category || "",
            options: allParameters[key].map((value) => ({
              value: value.value,
              formattedValue: value.formattedValue || "",
              checked: value.value === currentValue.value,
            })),
          };
        }
      });

      const sortedKeys = Object.keys(newParameters).sort();

      const sortedObj: Record<string, any> = {}; // Adjust the type as needed
      sortedKeys.forEach((key) => {
        sortedObj[key] = newParameters[key];
      });

      setParameters(sortedObj);
      setParametersLoaded(true);
    });
  };

  const applyRelativeDateFilter = (relativeDate: string) => {
    setSelectedDateOption(relativeDate);
    setDateRangeSelected(false);

    const dateRangeFunction = DATE_RANGE_MAPPINGS[relativeDate];
    if (!dateRangeFunction) return;

    const today = new Date();
    const { startDate, endDate } = dateRangeFunction(today);

    setSelectedDates([startDate, endDate]);
  };

  const applyDateFilter = (dates: Date[]) => {
    if (dates.length > 1) {
      const [startDate, endDate] = dates;
      const activeSheet = viz.workbook.activeSheet as Dashboard;
      const worksheets = activeSheet.worksheets;
      const worksheet = worksheets[activeSheet.index];

      if (worksheet && typeof worksheet.applyRangeFilterAsync === "function") {
        const dateFilter = TABLEAU_DATE_FILTER[reportAlias];
        worksheet.applyRangeFilterAsync(dateFilter, {
          min: startDate,
          max: endDate,
        });

        initFilters();
      } else {
        console.error(
          "Invalid worksheet or applyRangeFilterAsync method not available."
        );
      }
    }
  };

  const applyFilter = (
    field: string,
    key: string,
    selectedItems: SelectedItem[]
  ): void => {
    const selectedValues: any[] = selectedItems.map((item) => item.value);

    console.log("Applying filter:", field, "with values:", selectedValues);

    // Assuming there's a global 'viz' object for Tableau
    if (!viz) {
      console.log("viz is not available");
      return;
    }

    const worksheets = (viz.workbook.activeSheet as Dashboard).worksheets;
    const worksheet = worksheets[viz.workbook.activeSheet.index];

    if (worksheet && typeof worksheet.applyFilterAsync === "function") {
      worksheet.applyFilterAsync(
        field,
        selectedValues,
        FilterUpdateType.Replace,
        { isExcludeMode: false }
      );

      initFilters();
      console.log("attempted to apply filters to tableau");
    }
  };

  const applyParameter = (paramName: string, newValue: any): void => {
    viz.workbook
      .changeParameterValueAsync(paramName, newValue)
      .then((param: Parameter) => {
        initParams();
      })
      .catch((error: Error) => {
        console.error("Error changing parameter:", error);
      });
  };

  const handleClick = () => {
    const filterWrapper = document.getElementById("filter-wrapper");

    if (!filterWrapper) return;

    filterWrapper.classList.toggle("active");
  };

  return (
    <div className="tableau-filter-wrapper">
      <div
        id="filter-wrapper"
        className="fixed z-[99] max-w-[480px] right-0 top-0 h-full overflow-hidden bg-opposite w-full [border-left:1px_solid_#9999994d] flex flex-col items-center justify-around pl-[0] pr-[0] py-[10px]"
      >
        <div className="main-wrapper">
          <div
            className={classNames(
              "flex justify-start items-center w-full mb-6",
              {
                "mt-3": !isMobile(),
                "mt-10": isMobile(),
              }
            )}
          >
            <div className="pr-1" onClick={handleClick}>
              <div>
                <ArrowLeftBack className="text-white" />
              </div>
            </div>

            <h4 className="text-white text-center font-primary font-semibold text-2xl not-italic leading-[100%] ml-6">
              Filters
            </h4>
          </div>

          <div className="grid grid-cols-3 gap-4 justify-between items-center w-full h-10">
            {categories.map((c, i) => (
              <button
                key={i}
                className={classNames(
                  "font-primary px-4 py-2 text-sm font-normal text-white rounded-md !h-full bg-custom-darkTeal",
                  {
                    "!bg-primary": selectedFilterCategory === c.category,
                  }
                )}
                onClick={() => setSelectedFilterCategory(c.category)}
              >
                {c.title}
              </button>
            ))}
          </div>

          <div id="filter-section-wrapper">
            {selectedFilterCategory === "date" ? (
              <div className="px-2 w-full">
                <div className="w-full flex justify-center items-center border-b-[0.5px] mb-3 py-3 border-solid border-custom-darkTeal">
                  <h4 className="category-header">DATES</h4>
                </div>
                <div className="w-full flex flex-col gap-2 items-start">
                  <p className="font-primary font-white font-normal text-sm not-italic leading-[100%]">
                    DATE RANGE
                  </p>
                  <p className="w-full">
                    <input
                      type="text"
                      id="datePicker"
                      className={classNames(
                        "bg-custom-darkTeal px-4 py-2 text-sm font-normal text-white rounded-md outline-none !h-10",
                        "placeholder:text-white placeholder:text-sm placeholder:font-primary placeholder:opacity-60 placeholder:not-italic placeholder:font-normal",
                        {
                          "bg-primary": dateRangeSelected,
                        }
                      )}
                      placeholder="Select Date Range"
                    />
                  </p>
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <div className="w-full py-4 bg-transparent grid grid-cols-2 gap-2">
                    {relativeDateOptions.map((dateOption, i) => (
                      <button
                        key={`${dateOption}-${i}`}
                        id={`${dateOption}-${i}`}
                        className={classNames(
                          "bg-custom-darkTeal px-4 py-2 text-sm font-normal text-white rounded-md !h-10",
                          {
                            "active-date-button text-white":
                              selectedDateOption === dateOption &&
                              !dateRangeSelected,
                          }
                        )}
                        onClick={() => applyRelativeDateFilter(dateOption)}
                      >
                        {dateOption}
                      </button>
                    ))}
                  </div>
                  <div className="form-input">
                    {Object.keys(filters).map((key) => (
                      <TableauVizFilterDropdown
                        key={key}
                        filterKey={key}
                        filterData={filters[key]}
                        applyFilter={applyFilter}
                        activeKeys={activeKeys}
                        setActiveKeys={setActiveKeys}
                        setSelectedFilterCategory={setSelectedFilterCategory}
                        selectedFilterCategory={selectedFilterCategory}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}

            {selectedFilterCategory !== "date" ? (
              <div className="w-full px-2">
                <div className="flex flex-col gap-2 w-full">
                  <div className="w-full flex justify-center items-center border-t-[0.5px] border-b-[0.5px] py-3 border-solid border-custom-darkTeal">
                    <h4 className="category-header">
                      {selectedFilterCategory === "hierarchy"
                        ? "Hierarchy"
                        : "Additional"}
                    </h4>
                  </div>

                  {!filtersLoaded && selectedFilterCategory !== "date" ? (
                    <div className="m-auto flex items-center justify-center my-4">
                      <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 gap-2 w-full">
                      {Object.keys(filters).map((key) => (
                        <TableauVizFilterDropdown
                          key={key}
                          filterKey={key}
                          filterData={filters[key]}
                          applyFilter={applyFilter}
                          activeKeys={activeKeys}
                          setActiveKeys={setActiveKeys}
                          setSelectedFilterCategory={setSelectedFilterCategory}
                          selectedFilterCategory={selectedFilterCategory}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ) : null}

            <div className="w-full px-2 gap-2">
              <div className="w-full flex justify-center items-center border-t-[0.5px] border-b-[0.5px] mb-3 py-3 border-solid border-custom-darkTeal">
                <h4 className="category-header">GRAPH BY</h4>
              </div>

              {parametersLoaded ? (
                <div className="grid grid-cols-2 gap-2 w-full">
                  {Object.keys(parameters).map((key) => (
                    <TableauVizParameterDropdown
                      key={key}
                      parameterKey={key}
                      parameterData={parameters[key]}
                      selectedFilterCategory={selectedFilterCategory}
                      applyParameter={applyParameter}
                    />
                  ))}
                </div>
              ) : (
                <div className="m-auto flex items-center justify-center my-4">
                  <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableauFilter;
