import { TableauEventType, TableauViz } from "@tableau/embedding-api";
import { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchTableauJwtToken } from "../../redux/slices/reportSlice";
import TableauFilter from "./TableauFilter";

interface EmbeddedReportProps {
  reportAlias: string;
  reportUrl: string;
}

const EmbeddedReport = ({ reportAlias, reportUrl }: EmbeddedReportProps) => {
  const { tableauJwtToken } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();

  const vizRef = useRef<any>(null);
  const [isVizReady, setIsVizReady] = useState(false); // Add this state
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let viz: any = null;

    const initializeViz = () => {
      if (!tableauJwtToken || !reportUrl) {
        return;
      }

      // First, clean up existing viz
      const container = document.getElementById("tableauViz");
      if (container) {
        // Remove all child elements
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
      }
      if (vizRef.current) {
        vizRef.current.dispose();
        vizRef.current = null;
      }

      // Then initialize new viz
      viz = new TableauViz();
      viz.src = reportUrl;
      viz.height = window.innerHeight - 52 - 24 - 48;
      viz.width = "100%";
      viz.hideTabs = true;
      viz.toolbar = "hidden";
      viz.token = tableauJwtToken;

      viz.addEventListener(TableauEventType.FirstInteractive, function () {
        console.log("View is interactive");
        vizRef.current = viz;
        setIsVizReady(true); // Set ready state when viz is interactive
      });

      viz.addEventListener(
        TableauEventType.VizLoadError,
        function (event: any) {
          console.error("Tableau error:", event);
          setError("Failed to load visualization");
          setIsVizReady(false);
        }
      );

      container?.appendChild(viz);
    };

    initializeViz();

    // Cleanup function when component unmounts
    return () => {
      const container = document.getElementById("tableauViz");
      if (container) {
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }
      }
      viz = null;
      if (vizRef.current) {
        vizRef.current = null;
      }
      setIsVizReady(false);
      setError(null);
    };
  }, [tableauJwtToken, reportUrl]);

  useEffect(() => {
    dispatch(fetchTableauJwtToken());
  }, [dispatch]);

  return (
    <div className="container mx-auto">
      {error && <div className="text-red-600 p-4 text-center">{error}</div>}

      {tableauJwtToken && reportUrl && isVizReady && (
        <TableauFilter viz={vizRef.current} reportAlias={reportAlias} />
      )}

      <div id="tableauViz" className="w-full overflow-hidden" />
    </div>
  );
};

export default EmbeddedReport;
