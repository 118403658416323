import { useEffect, useState } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { SelectedDateType } from "../../const/enums/SelectedDateType";
import { IDateRange } from "../../interfaces/dateRange";
import { useAppDispatch } from "../../redux/hooks";
import { updateSelectedDateType } from "../../redux/slices/reportSlice";
import { formatDate } from "../../utils/date";

import Backdrop from "../Common/Backdrop";
import Button from "../Common/Button";
import DatePicker from "../Common/DatePicker";
import DropDown from "../Common/DropDown";

interface ReportDateRangePickerProps {
  isPopupOpen: boolean;
  closePopup: () => void;
  selectedDateType: string;
  selectedDateRange: IDateRange;
  setSelectedDateRange: (range: IDateRange) => void;
}

const ReportDateRangePicker = ({
  isPopupOpen,
  closePopup,
  selectedDateRange,
  setSelectedDateRange,
  selectedDateType,
}: ReportDateRangePickerProps) => {
  const dispatch = useAppDispatch();
  const [tempDateRange, setTempDateRange] = useState<IDateRange>({
    from: new Date(),
    to: new Date(),
  });
  // Calculate the current year and the previous 4 years
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const [datePickerState, setDatePickerState] = useState({
    date: {
      startDate: selectedDateRange.from,
      endDate: selectedDateRange.to,
      key: "date",
    },
  });

  const years = Array.from(
    { length: 20 },
    (_, index) => selectedYear - index
  ).map((year) => year.toString());

  useEffect(() => {
    setDatePickerState((prev) => {
      return {
        ...prev,
        date: {
          ...prev.date,
          startDate: selectedDateRange.from,
          endDate: selectedDateRange.to,
        },
      };
    });
  }, [selectedDateRange]);

  const updateRange = (dateRange: IDateRange) => {
    setTempDateRange(dateRange);

    setDatePickerState((prev) => {
      return {
        ...prev,
        date: {
          ...prev.date,
          startDate: dateRange.from,
          endDate: dateRange.to,
        },
      };
    });
  };
  const applyChange = () => {
    setSelectedDateRange(tempDateRange);
    dispatch(updateSelectedDateType(SelectedDateType.Custom));
    closePopup();
  };

  return (
    <Backdrop
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-overlay"
      contentClassName="absolute left-[50%] translate-x-[-50%] translate-y-[-50%] top-[50%] flex items-center justify-center"
    >
      <div className="flex flex-col bg-base p-4 gap-[16px]">
        <div className="flex flex-col  gap-[16px]">
          <span className="text-[#666666] text-left font-primary text-[12px] not-italic font-normal leading-[120%]">
            SELECT YEAR
          </span>

          <DropDown
            options={years}
            selectedOption={selectedYear.toString()}
            onOptionSelect={() => {
              setSelectedYear(selectedYear);
            }}
            label="Select Report"
            buttonWidth="w-[92px]"
            buttonBgColor="bg-base-highlight"
            buttonTextColor="text-opposite-highlight"
            dropdownBg="bg-base-highlight"
            dropdownText="text-opposite-highlight"
            className="px-0"
            buttonTextSize="text-3xl font-opposite"
          />
        </div>

        <div className="flex justify-between">
          <div className="flex flex-col gap-[8px]">
            <span className="text-[#666666] text-left font-primary text-[10px] not-italic font-normal leading-[120%]">
              FROM
            </span>

            <span className="text-opposite-highlight text-left font-primary text-[16px] not-italic font-semibold leading-[120%]">
              {formatDate(datePickerState.date.startDate)}
            </span>
          </div>

          <div className="flex flex-col gap-[8px]">
            <span className="text-[#666666] text-left font-primary text-[10px] not-italic font-normal leading-[120%]">
              TO
            </span>
            <span className="text-opposite-highlight text-left font-primary text-[16px] not-italic font-semibold leading-[120%]">
              {formatDate(datePickerState.date.endDate)}
            </span>
          </div>
        </div>

        <DatePicker
          datePickerState={datePickerState}
          setDateRange={updateRange}
        />

        <Button
          bgColor="bg-primary"
          activeColor="active:bg-neutral-200"
          className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm"
          onClick={applyChange}
        >
          <span
            className={`font-primary text-m not-italic font-medium leading-[120%] text-primaryText`}
          >
            SAVE
          </span>
        </Button>
      </div>
    </Backdrop>
  );
};

export default ReportDateRangePicker;
