import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";

import Button from "../../Common/Button";
import ProfileDateFilters from "./ProfileDateFilters";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { PageType } from "../../../const/enums/PageType";
import {
  setCurrentPage,
  setReportFilterOpened,
} from "../../../redux/slices/globalSlice";
import {
  applyFilter,
  updateAppliedDateType,
  updateProfileFilter,
  updateSelectedDateType,
} from "../../../redux/slices/profileSlice";

interface ProfileFilterProps {
  isPopupOpen: boolean;
}

export const Label = ({ label }: { label: string }) => {
  return (
    <span
      className={`text-primaryText font-primary text-sm not-italic font-semibold leading-[120%] ${"text-base-highlight"}`}
    >
      {label}
    </span>
  );
};

export const PanelHeader = ({ header }: { header: string }) => {
  return (
    <div className="text-primaryText py-[16px] text-center font-primary not-italic font-semibold leading-[120%] border-b-[1px] border-custom-white-10 ">
      {header}
    </div>
  );
};

const ProfileFilter = ({ isPopupOpen }: ProfileFilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { selectedDateType, selectedDateRange } = useAppSelector(
    (state) => state.profile
  );

  const onHandleApplyFilters = () => {
    const startDate = format(new Date(selectedDateRange[0]), "yyyy-MM-dd");
    const endDate = format(new Date(selectedDateRange[1]), "yyyy-MM-dd");

    searchParams.set("start_date", startDate);
    searchParams.set("end_date", endDate);

    setSearchParams(searchParams, { replace: true });

    dispatch(updateSelectedDateType(selectedDateType));
    dispatch(updateAppliedDateType(selectedDateType));

    dispatch(
      updateProfileFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );

    dispatch(applyFilter());

    dispatch(setCurrentPage(PageType.Profile));
    dispatch(setReportFilterOpened(false));
  };

  return (
    <div
      className={`fixed top-[0] left-[0px] w-full h-full  transition duration-300 ease-in-out z-30 max-h-fit-height ${
        isPopupOpen
          ? "opacity-100 scale-100 translate-y-[0%]"
          : "opacity-0 scale-95 translate-y-[100%] pointer-events-none"
      }`}
    >
      <div className="h-[52px] pointer-events-none"></div>
      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 overflow-y-scroll px-[12px] pt-[12px] pb-[12px] bg-opposite">
        <ProfileDateFilters />

        <div className="flex gap-[9px] pb-[16px]">
          <Button
            bgColor="bg-primary"
            activeColor="active:bg-neutral-200"
            className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm mt-[16px]"
            onClick={onHandleApplyFilters}
          >
            <span
              className={`font-primary text-m not-italic font-medium leading-[120%] text-base-highlight`}
            >
              Apply
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileFilter;
