import { useEffect, useRef, useState } from "react";
import MobilePopup, { MobilePopupPosition } from "../../Common/MobilePopup";
import CheckIcon from "../../icons/CheckIcon";
import isMobile from "is-mobile";

interface FilterOption {
  value: string;
  formattedValue: string;
  checked: boolean;
}

interface FilterData {
  category: string;
  title: string;
  field: string;
  options: FilterOption[];
}

interface TableauVizFilterDropdownProps {
  filterKey: string;
  activeKeys: string[];
  selectedFilterCategory: string;
  filterData: FilterData;
  applyFilter: (field: string, key: string, options: FilterOption[]) => void;
  setActiveKeys: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedFilterCategory: (category: string) => void;
}

const TableauVizFilterDropdown = ({
  filterKey,
  filterData,
  applyFilter,
  activeKeys,
  setActiveKeys,
  selectedFilterCategory,
}: TableauVizFilterDropdownProps) => {
  const filterIncrement = isMobile() ? 20 : 30;
  const dropdownListRef = useRef<HTMLDivElement>(null);

  const [checkboxOptions, setCheckboxOptions] = useState<FilterOption[]>(
    filterData.options
  );
  const [checkedItems, setCheckedItems] = useState<FilterOption[]>(
    filterData.options.filter((x) => x.checked)
  );
  const [areAllChecked, setAreAllChecked] = useState<boolean>();
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [loadedOptionsCount, setLoadedOptionsCount] = useState(filterIncrement); // Number of options to load initially

  useEffect(() => {
    setCheckboxOptions(filterData.options);
    setCheckedItems(filterData.options.filter((x) => x.checked));
    setAreAllChecked(
      filterData.options.length ===
        filterData.options.filter((x) => x.checked).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    handleSetStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedItems]);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleCheckAll = () => {
    const newCheckedItems = filterData.options.map((item) => ({
      ...item,
      checked: !areAllChecked,
    }));
    setCheckboxOptions(newCheckedItems);
    setAreAllChecked(!areAllChecked);
  };

  const handleCheckboxChange = (index: number) => {
    const newCheckedItems = [...checkboxOptions];
    newCheckedItems[index].checked = !newCheckedItems[index].checked;
    setCheckboxOptions(newCheckedItems);
    setAreAllChecked(newCheckedItems.every((item) => item.checked));
  };

  const filterOptions = checkboxOptions
    .map((item, index) => {
      return { ...item, id: index };
    })
    .filter((item) =>
      item.formattedValue.toLowerCase().includes(searchTerm.toLowerCase())
    );

  const handleApply = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!areAllChecked) {
      setActiveKeys([...activeKeys, filterKey]);
    } else {
      setActiveKeys((prevItem) => {
        return prevItem.filter((item: string) => item !== filterKey);
      });
    }

    applyFilter(
      filterData.field,
      filterKey,
      checkboxOptions.filter((item) => item.checked)
    );
    setCheckedItems(checkboxOptions.filter((x) => x.checked));
    toggleDropdown();
  };

  const handleSetStatus = () => {
    if (checkedItems.length <= 0) {
      setStatus("Select Options");
    } else if (checkedItems.length === 1) {
      setStatus(checkedItems[0].formattedValue);
    } else if (areAllChecked) {
      setStatus("All Selected");
    } else {
      setStatus(`${checkedItems.length} Selected`);
    }
  };

  if (selectedFilterCategory !== filterData?.category) {
    return null;
  }

  // Load more options when scrolling to the bottom
  const handleScroll = () => {
    const dropdown = dropdownListRef.current;
    if (!dropdown) return;
    if (
      dropdown.scrollTop + dropdown.clientHeight + 12 >=
      dropdown.scrollHeight
    ) {
      setLoadedOptionsCount((prev) => prev + filterIncrement); // Load 10 more options
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start text-sm font-primary gap-2">
      <p className="font-primary text-sm not-italic">{filterData.title}</p>

      <div
        ref={dropdownListRef}
        className={`dropdown ${filterKey}-dropdown ${isOpen ? "on" : ""}`}
        data-control="checkbox-dropdown"
      >
        <label
          className="flex justify-between items-center px-4 py-2 mb-2 rounded-sm cursor-pointer text-sm font-primary bg-custom-darkTeal !h-10"
          onClick={toggleDropdown}
        >
          {status}
        </label>

        <MobilePopup
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          backBg="bg-overlay"
          popupBg="bg-thecup-secondary-main"
          popupBorder="ring-0px"
          position={MobilePopupPosition.Bottom}
        >
          {isOpen && (
            <div
              className={`bg-custom-deepCharcoal max-h-height-normal !rounded-none !rounded-t-[16px] dropdown-list px-2 ${filterKey}`}
            >
              <div className="sticky top-0 p-4 border-b-[1px] border-custom-darkTeal border-solid flex items-center justify-center bg-custom-deepCharcoal">
                <p className="text-sm font-primary" style={{ color: "white" }}>
                  {filterData.title}
                </p>
              </div>

              <input
                type="text"
                className="p-2 mb-2 border-[1px] border-custom-darkTeal border-solid w-full text-white sticky top-[60px] mt-2 outline-none rounded-sm bg-custom-deepCharcoal"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <div className="px-4">
                <button
                  type="button"
                  data-toggle="check-all"
                  className="dropdown-option outline-none text-sm font-primary text-base-highlight !h-8"
                  onClick={handleCheckAll}
                >
                  {areAllChecked ? "Uncheck All" : "Check All"}
                </button>
              </div>

              <div
                className="bg-custom-deepCharcoal w-full max-h-[min(400px,30vh)] overflow-y-scroll"
                onScroll={handleScroll}
                ref={dropdownListRef}
              >
                {filterOptions
                  .slice(0, loadedOptionsCount)
                  .map((option, index) => (
                    <div className="w-full" key={index}>
                      <div
                        className="flex items-center px-4 py-3 border-b-[1px] border-custom-darkTeal border-solid"
                        onClick={() => handleCheckboxChange(option.id)} // Add click handler to the div
                      >
                        <input
                          type="checkbox"
                          className="hidden"
                          value={option.value}
                          checked={option.checked}
                          onChange={() => handleCheckboxChange(option.id)}
                        />

                        <div
                          className={`w-5 h-5 mr-2 rounded-md border-2 border-primaryText flex items-center justify-center cursor-pointer ${
                            option.checked ? "bg-custom-teal" : ""
                          }`}
                        >
                          {option.checked && (
                            <CheckIcon className="text-primaryText" />
                          )}
                        </div>

                        <span className="select-none text-primaryText font-primary text-sm not-italic font-semibold leading-[120%]">
                          {option.formattedValue}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="bottom-0 p-4 text-center justify-center bg-custom-deepCharcoal">
                <button
                  type="button"
                  className="apply-filter"
                  onClick={handleApply}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          )}
        </MobilePopup>
      </div>
    </div>
  );
};

export default TableauVizFilterDropdown;
