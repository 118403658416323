import CompetitionIcon from "../components/icons/CompetitionIcon";
import HomeIcon from "../components/icons/HomeIcon";
import LeaderBoardIcon from "../components/icons/LeaderBoardIcon";
import PayIcon from "../components/icons/PayIcon";
import SearchIcon from "../components/icons/SearchIcon";
import { PageType } from "../const/enums/PageType";

export const NavBarItems = [
  {
    icon: HomeIcon,
    name: "Home",
    link: "/",
    page: PageType.Home,
  },
  {
    icon: CompetitionIcon,
    name: "Competitions",
    link: "/competitions",
    page: PageType.Competition,
  },
  {
    icon: LeaderBoardIcon,
    name: "Leaderboard",
    link: "/leaderboard",
    page: PageType.Leaderboard,
  },
  {
    icon: SearchIcon,
    name: "Search/AI",
    link: "/search",
    page: PageType.Search,
  },
  {
    icon: PayIcon,
    name: "Pay",
    link: "/pay",
    page: PageType.Pay,
  },
];
