import { UserGroupIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import useDebounce from "../../hooks/useDebounce";
import { ProfileCategory } from "../../interfaces/profile";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { searchProfileAction } from "../../redux/slices/profileSlice";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup, { MobilePopupPosition } from "../Common/MobilePopup";

import SearchIcon from "../icons/SearchIcon";

interface Props {
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
}

export default function ProfileSearchModal({ open, onClose, onCancel }: Props) {
  const dispatch = useAppDispatch();
  const {
    searchedProfiles,
    isSearchingProfile,
    selectedDateRange,
    currentProfile,
  } = useAppSelector((state) => state.profile);
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState(ProfileCategory.Player);
  const [isFocused, setIsFocused] = useState(false);
  const [hints, setHints] = useState<{ name: string; photo?: string }[]>([]);

  const { debouncedValue: searchKey, debouncing } = useDebounce(search, 800);

  useEffect(() => {
    if (search?.length < 3) {
      return;
    }

    dispatch(searchProfileAction({ category, search }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    dispatch(searchProfileAction({ category, search: searchKey }));
  }, [category, searchKey, dispatch]);

  useEffect(() => {
    if (searchedProfiles.length > 0) {
      setHints(
        searchedProfiles
          .slice(0, 30)
          .map((profile) => ({ name: profile.name, photo: profile.photo }))
      );
    } else {
      setHints([]);
    }
  }, [searchedProfiles]);

  return (
    <MobilePopup
      isOpen={open}
      onClose={() => {
        onCancel();
      }}
      backBg="bg-overlay"
      popupBg="bg-custom-deepCharcoal"
      popupBorder="ring-custom-custom-white-30"
      position={MobilePopupPosition.Bottom}
    >
      <div className="pt-4 pb-6 px-4 flex justify-between items-center">
        <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
          Find Profile
        </h2>

        {
          <IconButton
            onClick={() => {
              if (currentProfile) onClose();
              else onCancel();
            }}
            padding={0}
          >
            <XMarkIcon className="size-6 text-primaryText" />
          </IconButton>
        }
      </div>

      <div className="min-h-[516px] ">
        <div className="w-full px-[12.5px]">
          <div className="relative w-full rounded-[8px] shadow-[0_0_0_0.4px_white] bg-custom-deepCharcoal10">
            <input
              placeholder="Search"
              type="text"
              className="bg-transparent rounded-[8px] h-[48px] px-[16px] p-[12px] text-white text-sm not-italic leading-[120%] transition-all outline-none focus:shadow-[0_0_8px_0_#FFC60C99] w-full"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setTimeout(() => setIsFocused(false), 100);
              }}
            />

            <IconButton
              onClick={() => {}}
              size={16}
              padding={0}
              className="hover:bg-transparent absolute top-3 right-2"
            >
              <SearchIcon className={"text-white"} />
            </IconButton>

            {isFocused && !search && (
              <div className="absolute left-0 right-0 max-h-[240px] overflow-y-auto top-[60px] bg-custom-deepCharcoal ring-[1px] ring-neutral-800 rounded-[8px] shadow-lg z-10">
                <div className="py-2">
                  {hints.map((hint, index) => (
                    <div
                      key={index}
                      className="flex gap-2 px-4 py-2 hover:bg-custom-deepCharcoal20 cursor-pointer text-white text-sm"
                      onClick={() => {
                        setSearch(hint.name);
                        setIsFocused(false);
                      }}
                    >
                      {hint.photo ? (
                        <img
                          src={hint.photo}
                          className="size-6 rounded-full border border-primary object-cover"
                          alt=""
                        />
                      ) : (
                        <UserGroupIcon className="size-6" />
                      )}
                      {hint.name}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        {search.length >= 3 ? (
          <div className="p-4">
            <div className="text-neutral-100 text-sm">Results:</div>
            <div
              className={classNames("mt-4 flex flex-col gap-3 max-h-[400px]", {
                " overflow-y-auto": searchedProfiles.length > 0,
              })}
            >
              {isSearchingProfile || debouncing ? (
                <div className="m-auto flex items-center justify-between mt-10">
                  <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
                </div>
              ) : searchedProfiles.length > 0 ? (
                searchedProfiles.map((item, index) => {
                  const startDate = format(
                    new Date(selectedDateRange[0]),
                    "yyyy-MM-dd"
                  );
                  const endDate = format(
                    new Date(selectedDateRange[1]),
                    "yyyy-MM-dd"
                  );
                  return (
                    <Link
                      key={index}
                      className="flex gap-2 items-center"
                      to={`/profile?name=${item.name}&category=${category}&start_date=${startDate}&end_date=${endDate}`}
                      onClick={onClose}
                    >
                      {item.photo ? (
                        <img
                          src={item.photo}
                          className="size-6 rounded-full border border-primary object-cover"
                          alt=""
                        />
                      ) : (
                        <UserGroupIcon className="size-6" />
                      )}
                      <div className="text-sm font-normal tracking-wide">
                        {item.name}
                      </div>
                    </Link>
                  );
                })
              ) : (
                <div className="text-xs text-neutral-400">No results</div>
              )}
            </div>
          </div>
        ) : (
          <div className="pb-24 px-3">
            <div className="text-sm text-neutral-100 py-3">
              Or choose category:
            </div>
            <div className="flex flex-col gap-3">
              {Object.values(ProfileCategory).map((c) => (
                <Button
                  key={c}
                  bgColor={category === c ? "bg-neutral-400" : "bg-white"}
                  textColor={category === c ? "text-neutral-900" : "text-black"}
                  className="text-sm font-medium w-full h-[36px]"
                  onClick={() => setCategory(c)}
                >
                  {c}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </MobilePopup>
  );
}
