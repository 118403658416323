export enum PageType {
  Home = "VSPN",
  Report = "Report",
  SideMenu = "SideMenu",
  ReportFilter = "Filters",
  Competition = "Competition",
  ReportViewList = "Views",
  ReportViewSave = "Save View",
  CompetitionViper = "Viper",
  CompetitionTheCup = "The Cup XVII",
  Profile = "Profile",
  Leaderboard = "Leaderboard",
  Search = "Search",
  Pay = "Pay",
}
