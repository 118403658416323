import { useMemo } from "react";
import { IReportViewSheet } from "../../../interfaces/reportView";
import { processChartContent } from "../helpers/chart";
import { IReportTableColumn } from "../../../interfaces/reportDataInfo";

const useChartData = (
  reportData: any[],
  photos: any[],
  groupBy: string | string[],
  groupByDate: string,
  reportSheet: IReportViewSheet,
  reportTableColumns: IReportTableColumn[]
) => {
  const {
    tableHeader,
    tableSubHeader,
    tableContent,
    chartContent: chartData,
  } = useMemo(
    () =>
      processChartContent(
        reportData,
        photos,
        groupBy,
        groupByDate,
        reportSheet,
        reportTableColumns
      ),
    [reportData, photos, groupBy, groupByDate, reportSheet, reportTableColumns]
  );

  return { chartData, tableHeader, tableSubHeader, tableContent };
};

export default useChartData;
