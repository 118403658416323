import { useState } from "react";
import { IS_NBA_MODE } from "../../const/config";
import { PROFILE_METRICS } from "../../const/profile";
import { toUSDFormat } from "../../utils";
import { UserGroupIcon } from "@heroicons/react/16/solid";

const INITIAL_ROWS = 10; // Number of rows to show initially
const LOAD_MORE_COUNT = 10; // Number of rows to load each time

const ProfileStatsPanel = ({
  category,
  stats,
}: {
  category: string;
  stats: any;
}) => {
  const [visibleRows, setVisibleRows] = useState(INITIAL_ROWS);

  // Data array
  const data = stats || [];
  const hasMore = visibleRows < data.length;

  return (
    <div>
      <table className="w-full">
        <thead>
          <tr className="border-b border-neutral-900">
            <th className="text-neutral-100 text-sm font-normal text-left w-[40%] py-2 pl-2">
              {category}
            </th>
            {(IS_NBA_MODE
              ? PROFILE_METRICS["NBA"]
              : PROFILE_METRICS["VSPN"]
            ).map((metric, index) => (
              <th
                className="text-neutral-100 text-center text-sm font-normal"
                key={index}
              >
                {metric.toUpperCase()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.slice(0, visibleRows).map((row: any, index: number) => {
            return (
              <tr key={index} className="border-b border-[#0B0A0A]">
                <td className="flex gap-2 w-full text-neutral-100 text-left py-3 pl-2">
                  {row.photo ? (
                    <img
                      src={row.photo}
                      className="rounded-full size-6 border border-neutral-900"
                      alt=""
                    />
                  ) : (
                    <UserGroupIcon className="size-6" />
                  )}
                  {row.name}
                </td>

                {(IS_NBA_MODE
                  ? PROFILE_METRICS["NBA"]
                  : PROFILE_METRICS["VSPN"]
                ).map((metric, index) => (
                  <th
                    className="text-neutral-100 text-center text-sm font-normal"
                    key={index}
                  >
                    {toUSDFormat(row.fields[metric].value, 1)}
                  </th>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {hasMore && (
        <button
          className="w-full mt-4 bg-neutral-800 text-white py-2 rounded hover:bg-neutral-700"
          onClick={() => setVisibleRows((prev) => prev + LOAD_MORE_COUNT)}
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default ProfileStatsPanel;
