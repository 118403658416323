import chroma from "chroma-js";

import DEFAULT_THEME_COLORS from "../const/theme";

// Define the type for the color palette
type ColorPalette = {
  [key: string]: string; // Allow any string key with string values
};

// Function to determine a contrasting color
const getContrastingColor = (color: string) => {
  const white = "#FFF";
  const black = "#252a34";

  // Calculate contrast ratios
  const contrastWithWhite = chroma.contrast(color, white);
  const contrastWithBlack = chroma.contrast(color, black);

  // Choose the color with the better contrast
  return contrastWithWhite > contrastWithBlack ? white : black;
};

export const applyTheme = (themeName: string) => {
  applyThemeColors(DEFAULT_THEME_COLORS[themeName || "Default"]);
};

export const balanceThemeColors = (colors: string[]) => {
  let oppositeColor = colors[0] ?? "#000";
  let highlightingColor = colors[1] ?? "#FFF";
  let primaryTextColor = colors[2] ?? "#fff";

  let primaryColor = colors[3] ?? "#a99655";
  let secondaryColor = colors[4] ?? "#fafafa";
  let neutralColor = colors[5] ?? "#9badb3";
  let darkTealColor = colors[6] ?? "#2c3333";

  const MIN_CONTRAST = 4.5; // WCAG minimum recommended contrast

  // Ensure primaryTextColor has enough contrast with oppositeColor
  if (chroma.contrast(primaryTextColor, oppositeColor) < MIN_CONTRAST) {
    primaryTextColor =
      chroma(oppositeColor).luminance() > 0.5 ? "#000" : "#FFF";
  }

  // Ensure darkTealColor has enough contrast with primaryTextColor
  if (chroma.contrast(darkTealColor, primaryTextColor) < MIN_CONTRAST) {
    darkTealColor =
      chroma(primaryTextColor).luminance() > 0.5
        ? chroma(primaryTextColor).darken(3).hex()
        : chroma(primaryTextColor).brighten(3).hex();
  }

  return [
    oppositeColor,
    highlightingColor,
    primaryTextColor,
    primaryColor,
    secondaryColor,
    neutralColor,
    darkTealColor,
  ];
};

export const applyThemeColors = (colors: string[]) => {
  const oppositeColor = colors[0] ?? "#000";
  const highlightingColor = colors[1] ?? "#FFF";
  const primaryTextColor = colors[2] ?? "#fff";

  const primaryColor = colors[3] ?? "#a99655";
  const secondaryColor = colors[4] ?? "#fafafa";
  const neutralColor = colors[5] ?? "#9badb3";
  const darkTealColor = colors[6] ?? "#2c3333";

  // Generate primary color variants (100 to 900)
  const primaryShades = chroma
    .scale([
      chroma(primaryColor).brighten(2),
      primaryColor,
      chroma(primaryColor).darken(2),
    ])
    .mode("lab")
    .colors(9); // Generates 9 colors from 100 to 900

  // Generate secondary color variants (100 to 900)
  const secondaryShades = chroma
    .scale([
      chroma(secondaryColor).brighten(2),
      secondaryColor,
      chroma(secondaryColor).darken(2),
    ])
    .mode("lab")
    .colors(9); // Generates 9 colors from 100 to 900

  // Generate secondary color variants (100 to 900)
  const neutralShades = chroma
    .scale([
      chroma(neutralColor).brighten(2),
      neutralColor,
      chroma(neutralColor).darken(3),
    ])
    .mode("lab")
    .colors(10); // Generates 9 colors from 100 to 900

  const palette: ColorPalette = {
    "--color-base": "#FFF",
    "--color-base-highlight": chroma("#FFF").darken(0.1).hex(),

    "--color-opposite": oppositeColor,
    "--color-opposite-highlight": "#0B0A0A",

    "--color-overlay": `${oppositeColor}66`,
    "--color-primary-text": primaryTextColor,

    "--color-primary": primaryColor,
    "--color-primary-100": primaryShades[0], // Lightest shade
    "--color-primary-200": primaryShades[1],
    "--color-primary-300": primaryShades[2],
    "--color-primary-400": primaryShades[3],
    "--color-primary-500": primaryShades[4], // Base color
    "--color-primary-600": primaryShades[5],
    "--color-primary-700": primaryShades[6],
    "--color-primary-800": primaryShades[7],
    "--color-primary-900": primaryShades[8], // Darkest shade

    "--color-secondary": secondaryColor,
    "--color-secondary-100": secondaryShades[0], // Lightest shade
    "--color-secondary-200": secondaryShades[1],
    "--color-secondary-300": secondaryShades[2],
    "--color-secondary-400": secondaryShades[3],
    "--color-secondary-500": secondaryShades[4], // Base color
    "--color-secondary-600": secondaryShades[5],
    "--color-secondary-700": secondaryShades[6],
    "--color-secondary-800": secondaryShades[7],
    "--color-secondary-900": secondaryShades[8], // Darkest shade

    "--color-neutral": neutralColor,
    "--color-neutral-100": neutralShades[0], // Lightest shade
    "--color-neutral-200": neutralShades[1],
    "--color-neutral-300": neutralShades[2],
    "--color-neutral-400": neutralShades[3],
    "--color-neutral-500": neutralShades[4], // Base color
    "--color-neutral-600": neutralShades[5],
    "--color-neutral-700": neutralShades[6],
    "--color-neutral-800": neutralShades[7],
    "--color-neutral-900": neutralShades[8], // Darkest shade
    "--color-neutral-1000": neutralShades[9], // Darkest shade

    "--color-custom-white-10": chroma(highlightingColor).alpha(0.06).hex(),
    "--color-custom-white-30": chroma(highlightingColor).alpha(0.18).hex(),
    "--color-custom-white-80": chroma(highlightingColor).alpha(0.5).hex(),
    "--color-custom-white-90": chroma(highlightingColor).alpha(0.56).hex(),
    "--color-custom-white-b3": chroma(highlightingColor).alpha(0.7).hex(),

    "--color-custom-grey-d9": "#D9D9D9",
    "--color-custom-grey-57": "#575757",
    "--color-custom-grey-dd": "#dddddd",
    "--color-custom-grey-fa": "#fafafa",
    "--color-custom-grey-a3": "#a3a3a3",

    "--color-custom-darkTeal": darkTealColor,
    "--color-custom-deepCharcoal": chroma(darkTealColor).darken(0.4).hex(),
    "--color-custom-deepCharcoal10": chroma(darkTealColor)
      .darken(0.4)
      .alpha(0.06)
      .hex(),
    "--color-custom-buttonText": getContrastingColor(secondaryColor),
  };

  // Set CSS variables
  Object.keys(palette).forEach((key: string) => {
    document.documentElement.style.setProperty(key, palette[key]);
  });

  return palette;
};
