const ComingSoon: React.FC = () => {
  return (
    <div
      className={`flex flex-col items-center justify-center gap-[18px] p-[12px] h-max-height max-h-fit-height max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative`}
    >
      <div className="pb-20">
        <h1 className="text-3xl font-semibold text-center text-custom-white-b3">
          Coming Soon
        </h1>
        <p className="text-center text-custom-white-30">
          Stay tuned for updates!
        </p>
      </div>
    </div>
  );
};

export default ComingSoon;
