import { DownloadOption } from "./../const/enums/DownloadOption";
// fileDownloadUtils.ts
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { IReportDataInfo } from "../interfaces/reportDataInfo";

/**
 * Downloads a report as a file in the specified format (CSV or Excel).
 * @param reportData - Array of data to be included in the report.
 * @param reportDataInfo - Metadata about the report, including column information.
 * @param downloadOption - The format in which to download the report (CSV or Excel).
 * @param fileName - The name of the file to be downloaded.
 */
export const downloadReportAsFile = (
  reportData: any[],
  reportDataInfo: IReportDataInfo,
  downloadOption: DownloadOption,
  fileName: string
) => {
  const tableHeader = ["", ...reportDataInfo.tableColumns.map((c) => c.name)];
  const tableHeaderFields = [
    "group_field",
    ...reportDataInfo.tableColumns.map((c) => c.field),
  ];
  const tableContent = reportData;

  // Transform the report data into an array of objects based on the headers
  const data = tableContent.map((row) =>
    tableHeaderFields.reduce((obj, header, index) => {
      obj[tableHeader[index]] = row[header];
      return obj;
    }, {} as Record<string, any>)
  );

  // Download the report in the specified format
  if (downloadOption === DownloadOption.CSV) {
    downloadCSV(tableHeader, data, `${fileName}.csv`);
  } else if (downloadOption === DownloadOption.EXCEL) {
    downloadExcel(tableHeader, data, `${fileName}.xlsx`);
  }
};

/**
 * Estimates the file sizes for the CSV and Excel formats based on the report data.
 * @param reportData - Array of data to be included in the report.
 * @param reportDataInfo - Metadata about the report, including column information.
 * @returns An object containing estimated sizes for CSV and Excel files in KB.
 */
export const getFileSizeEstimations = (
  reportData: any[],
  reportDataInfo: IReportDataInfo
) => {
  const tableHeader = ["", ...reportDataInfo.tableColumns.map((c) => c.name)];
  const tableHeaderFields = [
    "group_field",
    ...reportDataInfo.tableColumns.map((c) => c.field),
  ];
  const tableContent = reportData;

  // Transform the report data into an array of objects based on the headers
  const data = tableContent.map((row) =>
    tableHeaderFields.reduce((obj, header, index) => {
      obj[tableHeader[index]] = row[header];
      return obj;
    }, {} as Record<string, any>)
  );

  // Estimate sizes for both CSV and Excel formats
  const estimatedSizeKB = estimateCSVFileSize(reportData);
  const estimatedXLSXSizeKB = estimateXLSXFileSize(tableHeader, data);

  return {
    estimatedSizeKB,
    estimatedXLSXSizeKB,
  };
};

/**
 * Utility function to download data as a CSV file.
 * @param tableHeader - Array of table headers.
 * @param tableContent - Array of table content (data rows).
 * @param fileName - Optional file name (default is "table.csv").
 */
export const downloadCSV = (
  tableHeader: string[],
  tableContent: Record<string, any>[],
  fileName: string = "table.csv"
) => {
  // Convert the table content to CSV format using PapaParse
  const csv = Papa.unparse(tableContent);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  // Set up the link for downloading
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Utility function to download data as an Excel (XLSX) file.
 * @param tableHeader - Array of table headers.
 * @param tableContent - Array of table content (data rows).
 * @param fileName - Optional file name (default is "table.xlsx").
 */
export const downloadExcel = (
  tableHeader: string[],
  tableContent: Record<string, any>[],
  fileName: string = "table.xlsx"
) => {
  // Create a worksheet from the table content
  const worksheet = XLSX.utils.json_to_sheet(tableContent, {
    header: tableHeader,
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate an array buffer for the workbook in XLSX format
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);

  // Set up the link for downloading
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Estimate the CSV file size based on the table content.
 * @param tableContent - Array of table rows (each row is an object with key-value pairs).
 * @returns {number} - Estimated size in KB.
 */
export const estimateCSVFileSize = (
  tableContent: Record<string, any>[]
): number => {
  // Convert table content to CSV string using PapaParse
  const csvString = Papa.unparse(tableContent);

  // Calculate the size in bytes (each character is 1 byte in a UTF-8 encoded string)
  const csvSizeInBytes = new Blob([csvString]).size;

  // Convert bytes to kilobytes (KB)
  const csvSizeInKB = csvSizeInBytes / 1024;

  return csvSizeInKB;
};

/**
 * Estimate the Excel (XLSX) file size based on the table content.
 * @param tableHeader - Array of table headers to include in the Excel file.
 * @param tableContent - Array of table rows (each row is an object with key-value pairs).
 * @returns {number} - Estimated size in KB.
 */
export const estimateXLSXFileSize = (
  tableHeader: string[],
  tableContent: Record<string, any>[]
): number => {
  // Create a worksheet from the table content
  const worksheet = XLSX.utils.json_to_sheet(tableContent, {
    header: tableHeader,
  });

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  // Append the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate an array buffer for the workbook in XLSX format
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Calculate the size in bytes
  const excelSizeInBytes = excelBuffer.byteLength;

  // Convert bytes to kilobytes (KB)
  const excelSizeInKB = excelSizeInBytes / 1024;

  return excelSizeInKB;
};
