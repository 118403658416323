import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchHomeCardStatsAction } from "../../redux/slices/profileSlice";
import { toUSDFormat } from "../../utils";
import ProfileStatsPanel from "./ProfileStatsPanel";

export default function PersonalStats({
  cardId,
  category,
  profileQuery,
}: {
  cardId: string;
  category: string;
  profileQuery: any;
}) {
  const dispatch = useAppDispatch();
  const {
    homeStats: { [cardId]: stats },
  } = useAppSelector((state) => state.profile);

  useEffect(() => {
    dispatch(
      fetchHomeCardStatsAction({
        cardId,
        statsQuery: profileQuery,
        searchCategory: profileQuery.category,
      })
    );
  }, [cardId, dispatch, profileQuery]);

  if (!stats) {
    return <></>;
  }

  return (
    stats[category] && (
      <div className="w-full">
        {Array.isArray(stats[category]) ? (
          <ProfileStatsPanel category={category} stats={stats[category]} />
        ) : (
          <table className="w-full">
            <thead>
              <tr className="border-b border-neutral-900">
                <th className="text-neutral-100 text-sm font-normal text-left w-[50%] py-2 pl-2">
                  Stat
                </th>
                <th className="text-neutral-100 text-center text-sm font-normal">
                  Rank
                </th>
                <th className="text-neutral-100 text-center text-sm font-normal pr-2">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(stats[category].fields)
                .filter((row: any) => row.label !== "Photo")
                .map((row: any, index: number) => (
                  <tr key={index} className="border-b border-[#0B0A0A]">
                    <td className="text-neutral-100 text-left w-[50%] py-3 pl-2">
                      {row.label}
                    </td>
                    <td className="text-neutral-100 text-center">
                      {!!row.rank && (
                        <span className="px-1 text-xs rounded border border-[#655F5F]">
                          {row.rank}
                        </span>
                      )}
                    </td>
                    <td className="text-center font-semibold pr-2">
                      {toUSDFormat(row.value)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    )
  );
}
