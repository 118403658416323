import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";

import productionReportIcon from "../../assets/icons/ProductionReport.png";
import trashIcon from "../../assets/icons/trash-01.svg";
import CloseIcon from "../icons/CloseIcon";

import { FavoriteType } from "../../const/enums/FavoriteType";
import useParsedFavorites from "../../hooks/useParsedFavorites";
import {
  IFavoriteCompetition,
  IFavoriteReport,
} from "../../interfaces/favorite";
import { useAppDispatch } from "../../redux/hooks";
import {
  toggleFavorite,
  updateFavoritePositions,
} from "../../redux/slices/reportSlice";

interface EditFavoritePopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

interface FavoriteItemProps {
  index: number;
  favorite: IFavoriteReport | IFavoriteCompetition;
  isLast: boolean;
  onRemoveFavorite: () => void;
}

const FavoriteItem = ({
  index,
  favorite,
  isLast,
  onRemoveFavorite,
}: FavoriteItemProps) => {
  const { imageUrl } = favorite;

  // Type guard to differentiate between IFavoriteReport and IFavoriteCompetition
  const isReport = (
    favorite: IFavoriteReport | IFavoriteCompetition
  ): favorite is IFavoriteReport => {
    return favorite.favoriteType === FavoriteType.Report;
  };

  return (
    <Draggable
      draggableId={`${favorite.favoriteName}-${favorite.id}`}
      index={index}
    >
      {(provided, snapshot) => {
        return (
          <div
            className={`flex px-4 py-3 gap-3 items-center  ${
              !isLast && "border-b-[1px] border-solid border-neutral-900"
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...("position" in (provided.draggableProps.style ?? {})
                ? {
                    position: "absolute",
                    top: 72 * index + 78 + "px",
                    left: "auto",
                  }
                : {}),
            }}
          >
            <img
              src={imageUrl ?? productionReportIcon}
              alt="Profile Img"
              srcSet=""
              className="w-[48px] h-[48px] min-w-[48px] min-h-[48px] flex-shrink-0 rounded-[48px] border-[1px] border-solid border-neutral-700 8dp-penumbra"
            />

            <h2 className="flex-grow-[1] text-base-highlight font-primary not-italic font-semibold leading-[120%] select-none">
              {isReport(favorite) ? favorite.reportName : favorite.name}
            </h2>

            <IconButton
              icon={trashIcon}
              size={16}
              onClick={onRemoveFavorite}
            ></IconButton>
          </div>
        );
      }}
    </Draggable>
  );
};

const EditFavoritePopup = ({
  isPopupOpen,
  closePopup,
}: EditFavoritePopupProps) => {
  const dispatch = useAppDispatch();
  const parsedFavorites = useParsedFavorites();
  const [favorites, setFavorites] = useState<
    (IFavoriteReport | IFavoriteCompetition)[]
  >([]);

  useEffect(() => {
    setFavorites(parsedFavorites);
  }, [parsedFavorites]);

  const onSaveChange = () => {
    toast.promise(
      dispatch(
        updateFavoritePositions(
          favorites.map((f, index) => ({
            id: f.id,
            favoriteId: f.favoriteId,
            favoriteType: f.favoriteType,
            favoriteName: f.favoriteName,
            favoriteImageLink: f.favoriteImageLink,
            position: index,
          }))
        )
      ).unwrap(),
      {
        loading: "Saving favorites...",
        success: "Successfully saved favorites.",
        error: "Oops! Something went wrong while trying to save the favorites.",
      }
    );
    closePopup();
  };

  const onToggleFavorite = (index: number) => {
    toast.promise(
      dispatch(
        toggleFavorite({
          favoriteId: parsedFavorites[index].favoriteId,
          favoriteType: parsedFavorites[index].favoriteType,
          favoriteName: parsedFavorites[index].favoriteName,
          favoriteImageLink: parsedFavorites[index].favoriteImageLink,
        })
      ).unwrap(),
      {
        loading: "Removing from favorites...",
        success: "Successfully removed the report from your favorites.",
        error:
          "Oops! Something went wrong while trying to remove the report from your favorites.",
      }
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newFavorites = [...favorites];
    const [removed] = newFavorites.splice(result.source.index, 1);
    newFavorites.splice(result.destination.index, 0, removed);
    setFavorites(newFavorites);
  };

  return (
    <MobilePopup
      backBg="bg-overlay"
      popupBg="bg-custom-deepCharcoal"
      popupBorder="ring-secondary-900"
      isOpen={isPopupOpen}
      onClose={closePopup}
    >
      <div className="flex flex-col gap-m">
        <div className="flex items-center justify-between py-[16px] px-[19px] ">
          <div>
            <h2 className="text-textPrimary font-primary text-[18px] not-italic font-semibold leading-[120%]">
              Edit Favorites
            </h2>
          </div>

          <IconButton onClick={closePopup}>
            <CloseIcon className="text-primaryText" />
          </IconButton>

          <div className="w-[1px] h-[21px] mt-[12px] bg-neutral-900 fixed left-[50%] top-0"></div>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                className="droppable"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <div className=" flex flex-col">
                  {favorites.map((favorite, index) => {
                    return (
                      <FavoriteItem
                        key={favorite.id}
                        index={index}
                        favorite={favorite}
                        isLast={index === favorites.length - 1}
                        onRemoveFavorite={() => onToggleFavorite(index)}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="flex justify-center items-center px-[12px] py-[16px] gap-[16px] border-t-[1px] border-solid border-t-neutral-800">
          <Button
            bgColor="bg-base-highlight"
            activeColor="active:bg-neutral-200"
            className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
            onClick={closePopup}
          >
            <span className="font-primary text-opposite-highlight text-sm not-italic font-semibold leading-[120%]">
              Cancel
            </span>
          </Button>

          <Button
            activeColor="active:bg-primary-400"
            className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
            onClick={onSaveChange}
          >
            <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
              Save Changes
            </span>
          </Button>
        </div>
      </div>
    </MobilePopup>
  );
};

export default EditFavoritePopup;
