import Skeleton from "react-loading-skeleton";
import { getMinimizedDate } from "../../../../utils/date";
import { TimeUnit } from "../../../../const/enums/TimeUnit";

const chartNormalHeightOffset = 244;
const chartFullScreenHeightOffset = 60;

export const renderChart = (
  scale: number,
  isFitScreen: boolean,
  isFullScreen: boolean,
  loading: boolean,
  skeletonRows: number,
  visibleRows: number,
  chartData: any[]
) => {
  const maxData = Math.max(...chartData.map((row) => row["data"]));

  const rowHeight = isFitScreen
    ? (window.innerHeight -
        (isFullScreen
          ? chartFullScreenHeightOffset
          : chartNormalHeightOffset)) /
      (chartData?.length || 1)
    : "";

  return loading
    ? Array.from({ length: skeletonRows }).map((_, index) => {
        return (
          <div className="flex bg-custom-white-10 p-[8px] gap-ml" key={index}>
            <div className="rounded-m w-[100px] h-[60px] flex items-center">
              <Skeleton className="bg-custom-darkTeal animate-pulse w-[100px] h-[60px] rounded-m" />
            </div>

            <div className="flex grow-[1] items-center gap-[8px] justify-between">
              <Skeleton
                className="bg-custom-darkTeal animate-pulse rounded-m"
                width={160}
                height={60}
              />

              <Skeleton
                className="bg-custom-darkTeal animate-pulse w-full h-full rounded-m"
                width={20}
                height={12}
              />
            </div>
          </div>
        );
      })
    : chartData
        .slice(0, isFitScreen ? chartData.length : visibleRows)
        .map((row, index) => {
          return isFitScreen ? (
            <div
              className="flex bg-custom-white-10 p-[1px] gap-ml border-b-[1px] border-custom-deepCharcoal"
              style={{
                height: rowHeight,
              }}
              key={index}
            >
              <div className="w-[100px] bg-opposite-highlight rounded-m flex items-center justify-center">
                <span
                  className=" px-[8px] whitespace-nowrap overflow-hidden overflow-ellipsis bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]"
                  style={{
                    fontSize: Math.min(14, Math.max(10, (rowHeight || 0) - 6)),
                  }}
                >
                  {row["groupBy"]}
                </span>
              </div>

              <div className="flex grow-[1] items-center gap-[8px] justify-between">
                <div
                  className={`bg-chart-gradient-2 h-full`}
                  style={{
                    width:
                      maxData === 0
                        ? "0px"
                        : `calc(${(row["data"] / maxData) * 100}% - 40px)`,
                    height: `${100 + scale * 2}%`,
                  }}
                ></div>

                <span className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%] min-w-10">
                  {row["data"]}
                </span>
              </div>
            </div>
          ) : (
            <div className="flex bg-custom-white-10 p-[8px] gap-ml" key={index}>
              <div className="rounded-m w-[100px] h-[60px] relative">
                {row.photo === "" ? (
                  <div className="bg-opposite-highlight w-full h-full rounded-m"></div>
                ) : (
                  <img
                    src={row.photo}
                    alt=""
                    className="w-full h-full rounded-m object-cover"
                  />
                )}
                <span className="absolute w-full rounded-b-m bg-custom-white-30 px-[8px] py-[2px] whitespace-nowrap overflow-hidden overflow-ellipsis bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]">
                  {row["groupBy"]}
                </span>
              </div>

              <div className="flex grow-[1] items-center gap-[8px] justify-between">
                <div
                  className={`bg-chart-gradient-2 h-full`}
                  style={{
                    width:
                      maxData === 0
                        ? "0px"
                        : `calc(${(row["data"] / maxData) * 100}% - 40px)`,
                    height: `${100 + scale * 2}%`,
                  }}
                ></div>

                <span className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%]">
                  {row["data"]}
                </span>
              </div>
            </div>
          );
        });
};

export const renderDateChart = (
  scale: number,
  isFitScreen: boolean,
  isFullScreen: boolean,
  loading: boolean,
  skeletonRows: number,
  visibleRows: number,
  chartData: any[],
  groupByDate: string,
  chartWidth: number
) => {
  const maxData = Math.max(...chartData.map((row) => row["data"]));
  const rowWidth = isFitScreen
    ? (chartWidth - 20) / (chartData?.length || 1)
    : "";

  return loading
    ? Array.from({ length: skeletonRows }).map((_, index) => {
        return (
          <div
            className="flex flex-col bg-custom-white-10 p-[8px] gap-ml"
            key={index}
          >
            <div className="rounded-m w-[78px] flex items-center">
              <Skeleton className="bg-custom-darkTeal animate-pulse w-[78px] h-[16px] rounded-m" />
            </div>

            <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
              <Skeleton
                className="bg-custom-darkTeal animate-pulse w-full h-full rounded-m"
                width={20}
                height={12}
              />

              <Skeleton
                className="bg-custom-darkTeal animate-pulse rounded-m"
                width={78}
                height={240}
              />
            </div>
          </div>
        );
      })
    : chartData
        .slice(0, isFitScreen ? chartData.length : visibleRows)
        .map((row, index) => {
          return isFitScreen ? (
            <div
              className="flex flex-col bg-custom-white-10 px-[1px] gap-ml border-r-[1px] border-custom-deepCharcoal relative"
              key={index}
              style={{
                width: rowWidth,
              }}
            >
              <div className="rounded-m w-fit-content pt-[8px] m-auto whitespace-nowrap overflow-hidden overflow-ellipsis">
                <span
                  className="w-full rounded-m bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]"
                  style={{
                    writingMode: "vertical-lr",
                    width: Math.min(16, rowWidth || 16),
                  }}
                >
                  {getMinimizedDate(row["groupBy"], groupByDate)}
                </span>
              </div>

              <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                <span
                  className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%]"
                  style={{
                    writingMode: "vertical-lr",
                  }}
                >
                  {row["data"]}
                </span>
                <div
                  className={`bg-chart-gradient-2-0deg h-full`}
                  style={{
                    width: `${100 + scale * 2}%`,
                    height:
                      maxData === 0
                        ? 0
                        : `${Math.ceil((row["data"] * 100) / maxData)}%`,
                  }}
                ></div>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col bg-custom-white-10 p-[8px] gap-ml border-b-[1px] border-custom-deepCharcoal"
              key={index}
            >
              <div className="rounded-m whitespace-nowrap overflow-hidden overflow-ellipsis">
                <span className="w-full rounded-m bg-opposite-highlight px-[8px] py-[2px]  bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]">
                  {getMinimizedDate(row["groupBy"], groupByDate)}
                </span>
              </div>

              <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                <span className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%]">
                  {row["data"]}
                </span>

                <div
                  className={`bg-chart-gradient-2-0deg h-full`}
                  style={{
                    width: `${100 + scale * 2}%`,
                    height:
                      maxData === 0
                        ? 0
                        : `${Math.ceil((row["data"] * 100) / maxData)}%`,
                  }}
                ></div>
              </div>
            </div>
          );
        });
};

export const renderComparisonChart = (
  scale: number,
  isFitScreen: boolean,
  isFullScreen: boolean,
  loading: boolean,
  skeletonRows: number,
  visibleRows: number,
  chartData: any | any[],
  groupByDate: string,
  chartWidth: number
) => {
  const maxData = Math.max(
    ...chartData.map((row: any) => row["current"]),
    ...chartData.map((row: any) => row["previous"])
  );
  const rowWidth = isFitScreen
    ? (chartWidth - 20) / (chartData?.length || 1)
    : "";

  const fontSize = Math.min(12, ((rowWidth || 0) - 5) / 2);
  const barWidth = ((rowWidth || 0) - 5) / 2 + scale * 2;

  const labelHeight = groupByDate === TimeUnit.Months ? 24 : 48;

  return loading
    ? Array.from({ length: skeletonRows }).map((_, index) => {
        return (
          <div
            className="flex flex-col bg-custom-white-10 p-[8px] gap-ml"
            key={index}
          >
            <div className="rounded-m w-[78px] flex items-center">
              <Skeleton className="bg-custom-darkTeal animate-pulse w-[78px] h-[16px] rounded-m" />
            </div>

            <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
              <Skeleton
                className="bg-custom-darkTeal animate-pulse w-full h-full rounded-m"
                width={20}
                height={12}
              />
              <div className="flex gap-[4px]">
                <Skeleton
                  className="bg-custom-darkTeal animate-pulse rounded-m"
                  width={36}
                  height={240}
                />

                <Skeleton
                  className="bg-custom-darkTeal animate-pulse rounded-m"
                  width={36}
                  height={240}
                />
              </div>
            </div>
          </div>
        );
      })
    : chartData
        .slice(0, isFitScreen ? chartData.length : visibleRows)
        .map((row: any, index: number) => {
          const change = Math.ceil(
            ((row["current"] - row["previous"]) * 100) / (row["current"] || 1)
          );

          return isFitScreen ? (
            <div
              className="flex flex-col gap-[8px] border-r-[1px] border-custom-deepCharcoal relative"
              key={index}
              style={{
                width: rowWidth,
              }}
            >
              <div
                className="rounded-m w-fit-content m-auto whitespace-nowrap overflow-hidden overflow-ellipsis"
                style={{
                  height: labelHeight,
                }}
              >
                <span
                  className="w-full rounded-m px-[2px]  bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]"
                  style={{
                    writingMode: "vertical-lr",
                  }}
                >
                  {row["period"]}
                </span>
              </div>

              <div className="flex flex-col grow-[1] bg-custom-white-10 gap-[8px] items-center">
                <span
                  className={`text-right pt-s font-[Lora] text-[10px] not-italic font-bold leading-[100%] ${
                    change < 0 ? "text-error-200" : "text-[#D7FFC9]"
                  }`}
                  style={{
                    writingMode: "vertical-lr",
                  }}
                >
                  {change}%
                </span>

                <div className="flex grow-[1] gap-[1px]">
                  <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                    <span
                      className="[writing-mode:vertical-lr] text-primaryText font-secondary text-xs not-italic font-bold leading-[100%]"
                      style={{
                        fontSize: fontSize,
                      }}
                    >
                      {row["previous"]}
                    </span>

                    <div
                      className={`bg-chart-gradient-1-0deg h-full shadow-pen-umbra-1`}
                      style={{
                        width: barWidth,
                        height:
                          maxData === 0
                            ? 0
                            : `${Math.ceil(
                                (row["previous"] * 100) / maxData
                              )}%`,
                      }}
                    ></div>
                  </div>

                  <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                    <span
                      className="[writing-mode:vertical-lr] text-primaryText font-secondary text-xs not-italic font-bold leading-[100%]"
                      style={{
                        fontSize: fontSize,
                      }}
                    >
                      {row["current"]}
                    </span>

                    <div
                      className={`bg-chart-gradient-2-0deg h-full shadow-pen-umbra-1`}
                      style={{
                        width: barWidth,
                        height:
                          maxData === 0
                            ? 0
                            : `${Math.ceil((row["current"] * 100) / maxData)}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="flex flex-col gap-[12px] border-b-[1px] border-custom-deepCharcoal"
              key={index}
            >
              <div className="rounded-m whitespace-nowrap overflow-hidden overflow-ellipsis">
                <span className="w-full px-[8px] py-[2px]  bottom-[0px] left-[0px] text-primaryText text-center font-secondary text-xs not-italic font-bold leading-[100%]">
                  {row["period"]}
                </span>
              </div>

              <div className="flex flex-col grow-[1] gap-[8px] bg-custom-white-10 p-[8px]">
                <span
                  className={`text-center font-[Lora] text-[10px] not-italic font-bold leading-[100%] ${
                    change < 0 ? "text-error-200" : "text-[#D7FFC9]"
                  }`}
                >
                  {change}%
                </span>

                <div className="flex grow-[1] gap-[1px]">
                  <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                    <span className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%] min-w-[20px] max-w-[20px] text-center">
                      {row["previous"]}
                    </span>

                    <div
                      className={`bg-chart-gradient-1-0deg h-full shadow-8dp-umbra`}
                      style={{
                        width: `${100 + scale * 2}%`,
                        height:
                          maxData === 0
                            ? 0
                            : `${Math.ceil(
                                (row["previous"] * 100) / maxData
                              )}%`,
                      }}
                    ></div>
                  </div>

                  <div className="flex flex-col grow-[1] items-center gap-[8px] justify-end">
                    <span className="text-primaryText font-secondary text-xs not-italic font-bold leading-[100%] min-w-[20px] max-w-[20px] text-center">
                      {row["current"]}
                    </span>

                    <div
                      className={`bg-chart-gradient-2-0deg h-full`}
                      style={{
                        width: `${100 + scale * 2}%`,
                        height:
                          maxData === 0
                            ? 0
                            : `${Math.ceil((row["current"] * 100) / maxData)}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          );
        });
};
