import { useMemo } from "react";
import {
  getTableHeader,
  getTableSubHeader,
  processTableContent,
} from "../helpers/table";
import { IReportField } from "../../../interfaces/reportField";
import { IReportTableColumn } from "../../../interfaces/reportDataInfo";

const useTableData = (
  reportData: any[],
  photos: any[],
  groupBy: string | string[],
  reportFields: IReportField[],
  reportTableColumns: IReportTableColumn[]
) => {
  const filteredFields = reportFields.filter(
    (field) => field.field !== "photo" && field.field !== "sales_year"
  );

  const tableHeader = useMemo(
    () => getTableHeader(groupBy, filteredFields, reportTableColumns),
    [groupBy, filteredFields, reportTableColumns]
  );

  const tableSubHeader = useMemo(
    () => getTableSubHeader(reportData, filteredFields, reportTableColumns),
    [reportData, filteredFields, reportTableColumns]
  );

  const tableContent = useMemo(
    () =>
      processTableContent(
        reportData,
        photos,
        groupBy,
        filteredFields,
        reportTableColumns
      ),
    [reportData, photos, groupBy, filteredFields, reportTableColumns]
  );

  return { tableHeader, tableSubHeader, tableContent };
};

export default useTableData;
