export const TABLEAU_FILTERS: Record<
  string,
  Record<string, { title: string; category: string; section: string }>
> = {
  "Tableau Plus": {
    conference: {
      title: "Conference",
      category: "hierarchy",
      section: "hierarchy",
    },
    division: {
      title: "Division",
      category: "hierarchy",
      section: "hierarchy",
    },
    player_name: {
      title: "Player Name",
      category: "hierarchy",
      section: "hierarchy",
    },
    team: {
      title: "Team",
      category: "hierarchy",
      section: "hierarchy",
    },
    measure_names: {
      title: "Measure",
      category: "additional",
      section: "additional",
    },
    team_city: {
      title: "Team City",
      category: "additional",
      section: "additional",
    },
    start_position: {
      title: "Start Position",
      category: "additional",
      section: "additional",
    },
  },
  "Tableau Plus 2": {
    conference: {
      title: "Conference",
      category: "hierarchy",
      section: "hierarchy",
    },
    division: {
      title: "Division",
      category: "hierarchy",
      section: "hierarchy",
    },
    player_name: {
      title: "Player Name",
      category: "hierarchy",
      section: "hierarchy",
    },
    team: {
      title: "Team",
      category: "hierarchy",
      section: "hierarchy",
    },
    measure_names: {
      title: "Measure",
      category: "additional",
      section: "additional",
    },
    team_city: {
      title: "Team City",
      category: "additional",
      section: "additional",
    },
    start_position: {
      title: "Start Position",
      category: "additional",
      section: "additional",
    },
  },
  "Tableau Profile Stats": {
    conference: {
      title: "Conference",
      category: "hierarchy",
      section: "hierarchy",
    },
    division: {
      title: "Division",
      category: "hierarchy",
      section: "hierarchy",
    },
    player_name: {
      title: "Player Name",
      category: "hierarchy",
      section: "hierarchy",
    },
    team: {
      title: "Team",
      category: "hierarchy",
      section: "hierarchy",
    },
    measure_names: {
      title: "Measure",
      category: "additional",
      section: "additional",
    },
    team_city: {
      title: "Team City",
      category: "additional",
      section: "additional",
    },
    start_position: {
      title: "Start Position",
      category: "additional",
      section: "additional",
    },
  },
  Custom: {
    season_week: { title: "SEASON WEEK", category: "date", section: "date" },
    default_year: { title: "SALES YEAR", category: "date", section: "date" },
    master_channel: {
      title: "CHANNEL",
      category: "hierarchy",
      section: "hierarchy",
    },
    master_division: {
      title: "DIVISION",
      category: "hierarchy",
      section: "hierarchy",
    },
    master_region: {
      title: "REGION",
      category: "hierarchy",
      section: "hierarchy",
    },
    master_team: {
      title: "TEAM",
      category: "hierarchy",
      section: "hierarchy",
    },
    master_rep_name: {
      title: "REP",
      category: "hierarchy",
      section: "hierarchy",
    },

    master_block_set: {
      title: "CLOSER/SETTER",
      category: "additional",
      section: "additional",
    },

    cancelled: {
      title: "Cancelled",
      category: "additional",
      section: "additional",
    },
    master_lead_source: {
      title: "Lead Source",
      category: "additional",
      section: "additional",
    },
    master_recruit_source: {
      title: "Master Recruit Source",
      category: "additional",
      section: "additional",
    },
    has_adt: {
      title: "Has ADT",
      category: "additional",
      section: "additional",
    },
  },
};

export const TABLEAU_DATE_FILTER: Record<string, string> = {
  "Tableau Plus": "Game Date Est",
  "Tableau Plus 2": "Game Date Est",
  Custom: "Date",
};

export const TABLEAU_PARAMETERS: Record<
  string,
  Record<string, { title: string; category?: string; section?: string }>
> = {
  "Tableau Plus": {
    grouping: {
      title: "Grouping Level",
      category: "hierarchy",
      section: "graph_by",
    },

    sort: { title: "Sort By" },
  },
  "Tableau Plus 2": {
    grouping: {
      title: "Grouping Level",
      category: "hierarchy",
      section: "graph_by",
    },

    sort: { title: "Sort By" },
  },

  "Tableau Profile Stats": {
    grouping: {
      title: "Grouping Level",
      category: "hierarchy",
      section: "graph_by",
    },

    sort: { title: "Sort By" },
  },
  Custom: {
    grouping_level: {
      title: "Grouping Level",
      category: "hierarchy",
      section: "graph_by",
    },
    select_rankings_metric: {
      title: "Select Rankings Metric",
      category: "hierarchy",
      section: "graph_by",
    },
    sort_by: { title: "Sort By" },
  },
};

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const DATE_RANGE_MAPPINGS: Record<string, (today: Date) => DateRange> = {
  "Last Week": (today) => ({
    startDate: new Date(today.setDate(today.getDate() - 7)),
    endDate: new Date(),
  }),
  "Last Month": (today) => ({
    startDate: new Date(today.setMonth(today.getMonth() - 1)),
    endDate: new Date(),
  }),
  Today: (today) => ({
    startDate: new Date(today),
    endDate: new Date(today),
  }),
  YTD: (today) => ({
    startDate: new Date(today.getFullYear(), 0, 2),
    endDate: new Date(today),
  }),
  Yesterday: (today) => ({
    startDate: new Date(today.setDate(today.getDate() - 1)),
    endDate: new Date(),
  }),
  MTD: (today) => ({
    startDate: new Date(today.getFullYear(), today.getMonth(), 1),
    endDate: new Date(today),
  }),
  "Last Quarter": (today) => ({
    startDate: new Date(today.setMonth(today.getMonth() - 3)),
    endDate: new Date(),
  }),
  "Last Year": (today) => ({
    startDate: new Date(today.setFullYear(today.getFullYear() - 1)),
    endDate: new Date(),
  }),
};
