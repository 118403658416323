import { UserGroupIcon } from "@heroicons/react/16/solid";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";
import { HomeProfileCardType } from "../../const/enums/HomeProfileCardType";
import { IProfileRankData } from "../../interfaces/profileRankBoard";
import HomeEmptyIcon from "../icons/HomeEmptyIcon";

import IconButton from "../Common/IconButton";
import { IS_NBA_MODE } from "../../const/config";
import { PROFILE_METRICS } from "../../const/profile";
import { toUSDFormat } from "../../utils";

interface Props {
  board: IProfileRankData[];
  mainProfile: any;
  orderBy: string;
  orderDir: string;
  handleHomeCard: (
    name: string,
    type: HomeProfileCardType,
    params: any
  ) => void;
}

export default function RepBoard({
  mainProfile,
  board,
  orderBy,
  orderDir,
  handleHomeCard,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const active = board.findIndex((m) => m.entityValue === mainProfile?.name);

  const handleSort = (field: string) => {
    if (orderBy === field) {
      searchParams.set("order_by", orderBy);
      searchParams.set("order_dir", orderDir === "asc" ? "desc" : "asc");
    } else {
      searchParams.set("order_by", field);
      searchParams.set("order_dir", "asc");
    }
    setSearchParams(searchParams, { replace: true });
  };

  const handleHomeCardClick = () => {
    const cardName = mainProfile.category + "Boards";
    if (handleHomeCard)
      handleHomeCard(cardName, HomeProfileCardType.RankBoard, {
        category: mainProfile.category,
        orderBy,
        orderDir,
      });
  };

  return mainProfile && board ? (
    <div className="px-3 py-3">
      <div className="flex justify-between pr-4">
        <div className="font-bold mb-6">{mainProfile.category} Board</div>
        <IconButton
          size={24}
          padding={0}
          className={"hover:bg-transparent flex"}
          onClick={handleHomeCardClick}
        >
          <HomeEmptyIcon className="w-5 h-5 text-white" />
        </IconButton>
      </div>
      <table className="w-full">
        <thead className="">
          <tr className="border-b border-neutral-800">
            <th className="text-neutral-100 text-center text-xs font-normal py-2 px-1">
              RK
            </th>
            <th className="text-left text-xs font-normal px-1">
              {mainProfile.category}
            </th>

            {(IS_NBA_MODE
              ? PROFILE_METRICS["NBA"]
              : PROFILE_METRICS["VSPN"]
            ).map((metric, index) => {
              return (
                <th
                  className="text-left text-xs font-normal px-1 w-12 cursor-pointer"
                  onClick={() => handleSort(metric)}
                  key={index}
                >
                  <div className="flex items-center gap-1">
                    <span>
                      {metric.slice(0, 1).toUpperCase() + metric.slice(1)}
                    </span>
                    <SortIcon active={orderBy === metric} dir={orderDir} />
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {board.map((row, index) => (
            <tr key={row.entityValue} className="text-sm">
              <td className="p-0 text-center">
                <div
                  className={`px-1 py-3 rounded-l border-l-4 overflow-hidden ${
                    index === active
                      ? "bg-neutral-900 border-primary"
                      : "border-transparent"
                  }`}
                >
                  <span
                    className={`px-1 text-xs rounded ${
                      index === active
                        ? "text-neutral-950 bg-white"
                        : "border border-[#655F5F]"
                    }`}
                  >
                    {row[`${orderBy.toLowerCase()}_rank`]}
                  </span>
                </div>
              </td>
              <td
                className={`px-1 ${active === index ? "bg-neutral-900" : ""}`}
              >
                <div className="flex gap-2 items-center">
                  {row.photo ? (
                    <img
                      src={row.photo}
                      className="rounded-full size-6 border border-neutral-900"
                      alt=""
                    />
                  ) : (
                    <UserGroupIcon className="size-6" />
                  )}
                  <span className={active === index ? "font-semibold" : ""}>
                    {row.entityValue}
                  </span>
                </div>
              </td>

              {(IS_NBA_MODE
                ? PROFILE_METRICS["NBA"]
                : PROFILE_METRICS["VSPN"]
              ).map((metric) => {
                return (
                  <td
                    key={metric}
                    className={`pr-3 text-center ${
                      active === index ? "bg-neutral-900" : ""
                    }`}
                  >
                    {toUSDFormat(row[metric])}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="px-3 py-3">
      <p className="text-center font-primary text-sm not-italic font-semibold leading-[150%] mt-8">
        No information is available.
      </p>
      <p className="text-center font-primary text-sm not-italic text-custom-white-80 font-normal leading-[150%] mt-1">
        Please select a different date range.
      </p>
    </div>
  );
}

interface SortIconProps {
  dir: string;
  active: boolean;
}

const SortIcon = ({ dir, active }: SortIconProps) =>
  active ? (
    dir === "asc" ? (
      <ArrowUpIcon className="size-3" />
    ) : (
      <ArrowDownIcon className="size-3" />
    )
  ) : (
    <></>
  );
