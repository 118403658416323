import { ReactNode, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setPopupOpened } from "../../redux/slices/globalSlice";
import { PageType } from "../../const/enums/PageType";

export enum MobilePopupPosition {
  Center = "Center",
  Bottom = "Bottom",
}

interface MobilePopupProps {
  children?: ReactNode; // To render any custom content inside the popup
  isOpen: boolean; // Control the open/close state externally
  onClose: () => void; // Handle closing the popup externally
  backBg?: string;
  popupBg?: string;
  popupBorder?: string;
  position?: MobilePopupPosition;
}

const MobilePopup = ({
  children,
  isOpen,
  onClose,
  backBg = "bg-opposite-highlight",
  popupBg,
  popupBorder,
  position = MobilePopupPosition.Bottom,
}: MobilePopupProps) => {
  const dispatch = useAppDispatch();

  const currentPage = useAppSelector((state) => state.global.currentPage);

  useEffect(() => {
    dispatch(setPopupOpened(isOpen));
  }, [dispatch, isOpen]);

  return (
    <div
      className={`fixed inset-0 z-50 flex justify-center items-end transition-opacity duration-300 ease-out ${backBg} ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      } ${
        position === MobilePopupPosition.Bottom ? "items-end" : "items-center"
      }`}
      onClick={onClose}
    >
      <div
        className={`w-full md:w-1/3 md:min-w-[480px] ring-[1px] ring-neutral-800 transform transition-all duration-300 ease-out ${popupBg} ${popupBorder} ${
          isOpen
            ? "translate-y-0 scale-100 opacity-100"
            : "translate-y-full scale-95 opacity-0"
        }
        ${
          position === MobilePopupPosition.Bottom
            ? "rounded-t-m"
            : "rounded-sm px-3 py-1"
        }
        ${currentPage === PageType.SideMenu ? "bottom-[72px] absolute" : ""}`}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default MobilePopup;
