import { getTablePhotoLink } from ".";
import { IReportTableColumn } from "../../../interfaces/reportDataInfo";
import { IReportField } from "../../../interfaces/reportField";

export const getTableHeader = (
  groupBy: string | string[],
  fields: IReportField[],
  reportTableColumns: IReportTableColumn[]
) => {
  if (fields.length === 0) return [""];
  return [
    Array.isArray(groupBy) ? groupBy[0] : groupBy,
    ...fields
      .filter((field) => field.fieldVisible)
      .map(
        (field) => reportTableColumns.find((c) => c.field === field.field)?.name
      )
      .filter(
        (fieldName) =>
          fieldName && fieldName !== "Photo" && fieldName !== "Sales Year"
      ),
  ] as string[];
};

export const getTableSubHeader = (
  data: any[],
  fields: IReportField[],
  columns: IReportTableColumn[]
) => {
  const result = [...data].pop();
  const { photo, ...filteredResult } = result || {};
  const subHeaderValues = { group_field: "Total", ...filteredResult };
  const subHeader = fields
    .filter((field) => field.fieldVisible)
    .map((field) => {
      const column = columns.find((c) => c.field === field.field);

      if (!column) {
        return "";
      }
      return subHeaderValues[column.field];
    });

  return ["Total", ...subHeader];
};

export const processTableContent = (
  data: any[],
  photos: any[],
  groupBy: string | string[],
  fields: IReportField[],
  columns: IReportTableColumn[]
) => {
  let result = [...data];
  result.pop();

  const tableContent = result.map((row) => {
    const filteredRow = [
      { field: "group_field", fieldVisible: true },
      ...fields,
    ]
      .filter((field) => field.fieldVisible)
      .map((field) => {
        const column = columns.find((c) => c.field === field.field);
        const key = field.field === "group_field" ? field.field : column?.field;

        if (key) {
          return row[key];
        }
        return "";
      });

    return {
      ...filteredRow,
      photo: getTablePhotoLink(row, photos, groupBy),
    };
  });

  return tableContent.sort(
    (row1, row2) => Object.values(row2)[1] - Object.values(row1)[1]
  );
};
