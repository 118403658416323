export interface IProfile {
  name: string;
  role?: string;
  photo?: string;
  entity_top_value: string;
  entity_top_level: string;
  entity_top2_value: string;
  entity_top2_level: string;
  [key: string]: any;
}

// Profile
// export enum ProfileCategory {
//   Rep = "Rep",
//   Team = "Team",
//   Region = "Region",
//   Division = "Division",
//   Channel = "Channel",
// }

export const ProfileCategory = {
  Player: "Player",
  Team: "Team",
  Division: "Division",
  Conference: "Conference",
};
