import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./custom.css";
import { IDateRange } from "../../../interfaces/dateRange";

interface DatePickerData {
  date: {
    startDate: Date;
    endDate: Date;
    key: string;
  };
}

interface DatePickerProps {
  datePickerState: DatePickerData;
  setDateRange: (dateRange: IDateRange) => void;
}

const DatePicker = ({ datePickerState, setDateRange }: DatePickerProps) => {
  return (
    <DateRangePicker
      onChange={(item: any) =>
        setDateRange({ from: item.date.startDate, to: item.date.endDate })
      }
      months={1}
      minDate={addDays(new Date(), -365 * 20)}
      maxDate={addDays(new Date(), 0)}
      direction="vertical"
      scroll={{ enabled: true }}
      ranges={[datePickerState.date]}
    />
  );
};

export default DatePicker;
