import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

import { FitOption } from "../../const/enums/FitOptions";
import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { ViewType } from "../../const/enums/ViewType";

import { useScreenActions } from "../../hooks/useReportActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setCurrentReportFilterCategory,
  setReportFilterOpened,
  setReportViewOpened,
} from "../../redux/slices/globalSlice";
import {
  fetchPhotos,
  fetchReportData,
  fetchReportDataInfo,
  fetchReportFilterList,
  saveHomeSheetAction,
  setCurrentReport,
  updateReportFields,
  updateReportFilter,
} from "../../redux/slices/reportSlice";

import { IReportFilterView } from "../../interfaces/reportFilterView";
import { IReportView, IReportViewSheet } from "../../interfaces/reportView";

import { useReportActionContext } from "../../context/ReportActionContext";
import { getQueryParam } from "../../utils";
import {
  getReportAliasFromLocation,
  processTableauReport,
} from "../../utils/report";

import SelectThemePopup from "../Theme/SelectThemePopup";
import CustomReport from "./CustomReport";
import EmbeddedReport from "./EmbedReport";
import ReportControls from "./ReportControls";
import ReportFilter from "./ReportFilter";
import ReportView from "./ReportView";
import SelectReportViewPopup from "./ReportView/SelectReportViewPopup";
import TutorialVideo from "./TutorialVideo";

interface ReportProps {
  fitOption: FitOption[];
  viewType: ViewType[];
  isFullScreen: boolean[];
  scale: number[];
  toggleFullScreen: (index: number) => void;
  toggleViewType: (index: number) => void;
  handleFitOption: (index: number, newOption: FitOption) => void;
  increaseScale: (index: number) => void;
  decreaseScale: (index: number) => void;
  reportRefs: React.MutableRefObject<React.RefObject<HTMLDivElement>[]>;
}

const Report = ({
  fitOption,
  viewType,
  isFullScreen,
  scale,
  handleFitOption,
  toggleViewType,
  toggleFullScreen,
  increaseScale,
  decreaseScale,
  reportRefs,
}: ReportProps) => {
  const { fetchSelectedFilterView, fetchAllFilterViews } =
    useReportActionContext();
  // Use useAppDispatch to use dispatch
  const dispatch = useAppDispatch();

  // Use useLocation to get query parameters
  const location = useLocation();

  // Use screen actions hook
  const { handleShareSC, handleDownload } = useScreenActions();

  // Get current page type
  const { currentPage, isReportFilterOpened, isReportViewOpened } =
    useAppSelector((state) => state.global);

  // Get reports from the Redux store
  const {
    reportData,
    currentReport,
    reports,
    selectedDateRange,
    appliedFilters,
    reportDataInfo,
    reportFields,
    homeCards: homeSheets,
  } = useAppSelector((state) => state.report);

  // State to handle theme popup and selected report
  const [isSelectThemeOpen, setIsSelectThemeOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [isSelectReportViewOpen, setIsSelectReportViewOpen] = useState(false);

  const [homeSheetInfo, setHomeSheetInfo] = useState<{
    view: IReportView;
    sheet: IReportViewSheet;
  }>();

  const [view, setView] = useState<IReportView>();

  const openSelectTheme = () => {
    setIsSelectThemeOpen(true);
  };

  const closeSelectTheme = () => {
    setIsSelectThemeOpen(false);
  };

  const openVideo = () => {
    setIsVideoOpen(true);
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
  };

  const openReportFilter = (category: ReportFilterCategory) => {
    dispatch(setCurrentPage(PageType.ReportFilter));
    dispatch(setCurrentReportFilterCategory(category));
    dispatch(setReportFilterOpened(true));
  };

  const openTableauFilter = () => {
    const filterWrapper = document.getElementById("filter-wrapper");

    if (!filterWrapper) return;

    filterWrapper.classList.toggle("active");
  };

  const openReportView = () => {
    dispatch(setCurrentPage(PageType.ReportViewList));
    dispatch(setReportViewOpened(true));
  };

  const handleToggleHomeSheet = (
    view: IReportView,
    sheet: IReportViewSheet,
    filterView: IReportFilterView
  ) => {
    if (!currentReport || !currentReport.id) return;

    toast.promise(
      dispatch(
        saveHomeSheetAction({
          reportId: currentReport.id,
          reportViewName: view.viewName,
          reportFilterViewName: filterView.viewName,
          reportFilters: filterView.filters,
          reportFields: filterView.fields,
          sheetType: sheet.sheetType,
          sheetField: sheet.sheetField || "",
        })
      ).unwrap(),
      {
        loading: "Adding sheet to home screen",
        success: () => {
          return `Added sheet to home screen.`;
        },
        error: "An error occurred while adding sheet to home screen.",
      }
    );

    return;
  };

  const renderReport = () => {
    if (currentReport) {
      if (currentReport.reportType === "tableau") {
        return (
          <EmbeddedReport
            reportAlias={currentReport.alias}
            reportUrl={currentReport.reportUrl}
          />
        );
      } else {
        if (
          view &&
          reportData.data.length === view.viewSheets.length &&
          reportData.name === currentReport.alias
        ) {
          return (
            <div className="flex flex-col gap-2">
              {view.viewSheets.map((sheet, index) => {
                const isHomeSheet =
                  homeSheets.find(
                    (homeSheet) =>
                      homeSheet.reportId === currentReport?.id &&
                      homeSheet.reportViewName === view.viewName &&
                      homeSheet.sheetType === sheet.sheetType &&
                      homeSheet.sheetField === (sheet.sheetField || "")
                  ) !== undefined;

                return (
                  <CustomReport
                    key={index}
                    ref={reportRefs.current[index]}
                    reportData={reportData.data[index]}
                    isHomeSheet={isHomeSheet}
                    viewType={viewType[index]}
                    reportSheet={sheet}
                    handleToggleHomeSheet={() => {
                      setIsSelectReportViewOpen(true);
                      setHomeSheetInfo({ view, sheet });
                    }}
                    scale={scale[index]}
                    isFilterOpen={isReportFilterOpened}
                    fitOption={fitOption[index]}
                    isFullScreen={isFullScreen[index]}
                    toggleFullScreen={() => toggleFullScreen(index)}
                    toggleViewType={() => toggleViewType(index)}
                    handleFitOption={(option: FitOption) =>
                      handleFitOption(index, option)
                    }
                    handleShareSC={handleShareSC}
                    handleDownload={handleDownload}
                    increaseScale={() => increaseScale(index)}
                    decreaseScale={() => decreaseScale(index)}
                  />
                );
              })}
            </div>
          );
        }

        return (
          <div className="m-auto flex items-center justify-between mt-10">
            <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
          </div>
        );
      }
    }

    return (
      <div className="mt-4">
        <p className="text-center text-neutral-700">
          No report found for the given alias.
        </p>
      </div>
    );
  };

  useEffect(() => {
    const viewName = getQueryParam(location, "view");

    // Exit early if no views are available
    if (reportDataInfo.view.length === 0) return;

    const setViewAndFilters = (newView: IReportView) => {
      (newView.reportName !== view?.reportName ||
        newView.viewName !== view?.viewName) &&
        setView(newView);
    };

    // Check if viewName exists in the query params
    if (!viewName) {
      const firstView = reportDataInfo.view[0]; // Default to first view
      setViewAndFilters(firstView);
    } else {
      const selectedView = reportDataInfo.view.find(
        (view) => view.viewName === viewName
      );
      if (selectedView) {
        setViewAndFilters(selectedView);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location, reportDataInfo.view]);

  useEffect(() => {
    const reportAlias = getReportAliasFromLocation(location);
    const report = processTableauReport(reports, reportAlias);

    if (!reportAlias || !report?.alias) return;

    dispatch(setCurrentReport(report));
    dispatch(fetchReportDataInfo({ reportName: report?.alias || "" }));
  }, [dispatch, location, reports]);

  useEffect(() => {
    if (currentPage === PageType.Report) {
      dispatch(setReportFilterOpened(false));
    }
  }, [currentPage, dispatch]);

  useEffect(() => {
    dispatch(fetchPhotos());
    dispatch(fetchReportFilterList({ reportName: currentReport?.alias || "" }));
    dispatch(
      updateReportFilter({
        filterName: "Date",
        selectedOption: selectedDateRange,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentReport?.reportName]);

  useEffect(() => {
    fetchAllFilterViews();
    fetchSelectedFilterView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  useEffect(() => {
    if (
      !currentReport?.alias ||
      !view?.viewName ||
      view.reportName !== currentReport.alias ||
      Object.keys(appliedFilters).length === 0
    )
      return;

    dispatch(
      fetchReportData({
        reportName: currentReport.alias,
        reportView: view.viewName,
        filters: appliedFilters,
      })
    );
  }, [currentReport, dispatch, appliedFilters, view]);

  useEffect(() => {
    if (reportFields.length === 0) {
      const fields = reportDataInfo.tableColumns.map((c) => {
        return {
          field: c.field,
          fieldVisible: true,
        };
      });
      dispatch(updateReportFields(fields));
    }
  }, [dispatch, reportDataInfo.tableColumns, reportFields.length]);

  return (
    <div
      className={`flex flex-col gap-[24px] 
        ${isReportFilterOpened ? "overflow-hidden" : ""}     
        `}
    >
      <ReportControls
        openSelectTheme={openSelectTheme}
        openVideo={openVideo}
        openReportView={openReportView}
        openReportFilter={openReportFilter}
        selectedReport={currentReport}
        openTableauFilter={openTableauFilter}
      />

      {/* Render the embedded report if found */}
      {renderReport()}

      <SelectThemePopup
        isPopupOpen={isSelectThemeOpen}
        closePopup={closeSelectTheme}
      />

      <SelectReportViewPopup
        isPopupOpen={isSelectReportViewOpen}
        closePopup={() => {
          setIsSelectReportViewOpen(false);
        }}
        onConfirm={(reportFilterView: IReportFilterView) => {
          if (!homeSheetInfo) return;

          setIsSelectReportViewOpen(false);
          handleToggleHomeSheet(
            homeSheetInfo.view,
            homeSheetInfo.sheet,
            reportFilterView
          );
        }}
      />

      <ReportFilter isPopupOpen={isReportFilterOpened} />

      <ReportView isPopupOpen={isReportViewOpened} />

      {currentReport?.tutorialVideoLink && (
        <TutorialVideo
          title={currentReport.reportName}
          link={currentReport.tutorialVideoLink}
          isPopupOpen={isVideoOpen}
          closePopup={closeVideo}
        />
      )}
    </div>
  );
};

export default Report;
