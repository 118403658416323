import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { ReportSheetType } from "../../const/enums/ReportSheetType";
import { TimeUnit } from "../../const/enums/TimeUnit";
import { FitOption } from "../../const/enums/FitOptions";
import {
  HomeCompetitionCardType,
  SheetCategory,
} from "../../const/enums/HomeCompetitionCardType";
import { IHomeCard, IHomeSheet } from "../../interfaces/homeSheet";
import { IPhoto } from "../../interfaces/photo";
import { IReportDataInfo } from "../../interfaces/reportDataInfo";
import { IHomeCompetitionCard } from "../../interfaces/homeCompetitionCard";
import { IHomeProfileCard } from "../../interfaces/homeProfileCard";
import { HomeProfileCardType } from "../../const/enums/HomeProfileCardType";

import Button from "../Common/Button";
import Chart from "../Common/Chart";
import ComparisonChart from "../Common/Chart/ComparisonChart";
import DateChart from "../Common/Chart/DateChart";
import Table from "../Common/Table";

import UserIcon from "../icons/UserIcon";
import UsersIcon from "../icons/UsersIcon";

import useChartData from "../Report/hooks/useChartData";
import useTableData from "../Report/hooks/useTableData";

import ChampionShipFinal from "../Competition/Viper/Overview/ChampionShipFinal";
import ViperMyPerformance from "../Competition/Viper/Common/ViperMyPerformance";
import ViperBracketsCard from "../Competition/Viper/Brackets/ViperBracketsCard";
import ViperMatchDataTableCard from "../Competition/Viper/SevenWeeks/ViperMatchDataTableCard";
import MyMatchUp from "../Competition/TheCup/Overview/MyMatchUp";
import OtherMatchUp from "../Competition/TheCup/Overview/OtherMatchUp";
import TheCupSeeding from "../Competition/TheCup/TheCupSeedingTab";
import TheCupBracketsCard from "../Competition/TheCup/Brackets/TheCupBracketsCard";
import PersonalStats from "../Profile/PersonalStatsCard";
import RepBoard from "../Profile/RepBoardCard";

interface HomeCardsSectionProps {
  cards: IHomeCard[];
  sheetsData: { name: string; data: any[] };
  sheetsInfo: IReportDataInfo[];
  isLoading: boolean;
  photos: IPhoto[];
  openPopup: () => void;
}

const ReportSheet = ({
  reportSheet,
  sheetInfo,
  reportData,
  photos,
  isLoading,
}: {
  reportSheet: IHomeSheet;
  sheetInfo: IReportDataInfo;
  reportData: any[];
  photos: IPhoto[];
  isLoading: boolean;
}) => {
  const reportFilters = JSON.parse(reportSheet.reportFilters);
  const reportFields = JSON.parse(reportSheet.reportFields);
  const groupBy = reportFilters["GroupBy"] || [sheetInfo.filterDefaultGroupBy];

  const groupByDate =
    typeof reportFilters["GroupByDate"] === "string"
      ? reportFilters["GroupByDate"]
      : TimeUnit.Days;

  const { tableHeader, tableSubHeader, tableContent } = useTableData(
    reportData,
    photos,
    groupBy,
    reportFields,
    sheetInfo.tableColumns
  );

  const { chartData } = useChartData(
    reportData,
    photos,
    groupBy,
    groupByDate,
    reportSheet,
    sheetInfo.tableColumns
  );

  if (reportSheet.sheetType === ReportSheetType.Table) {
    return (
      <Table
        scale={0}
        fitOption={FitOption.SCROLL}
        isFullScreen={false}
        loading={isLoading}
        isMinHeight={true}
        tableHeader={tableHeader}
        tableSubHeader={tableSubHeader}
        tableContent={tableContent}
        className={"rounded-sm"}
        handleOnClickEntity={() => {}}
        defaultIcon={
          groupBy === "Rep" ? (
            <UserIcon
              className={"text-primaryText w-[28px] h-[28px] min-w-[28px]"}
            />
          ) : (
            <UsersIcon
              className={"text-primaryText w-[28px] h-[28px] min-w-[28px]"}
            />
          )
        }
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.Chart) {
    return (
      <Chart
        scale={0}
        isFullScreen={false}
        isFitScreen={false}
        loading={isLoading}
        className={"rounded-sm"}
        chartData={chartData}
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.ChartByDate) {
    return (
      <DateChart
        scale={0}
        isFullScreen={false}
        isFitScreen={false}
        loading={isLoading}
        className={"rounded-sm"}
        chartData={chartData}
        groupByDate={groupByDate}
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.ComparisonChart) {
    const years: number[] = [];
    reportData.forEach((row) => {
      if (!row.group_field) return;
      const date = new Date(row.group_field);
      const year = date.getFullYear();
      years.push(year);
    });

    return (
      <>
        <div className="flex bg-custom-deepCharcoal pt-[10px]">
          <div className="flex items-center">
            <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-1 rounded-[50%]"></div>
            <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
              {chartData ? Math.max(...years) - 1 : ""}
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-2 rounded-[50%]"></div>
            <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
              {chartData ? Math.max(...years) : ""}
            </div>
          </div>
        </div>
        <ComparisonChart
          scale={0}
          isFullScreen={false}
          isFitScreen={false}
          loading={false}
          className={"rounded-sm"}
          chartData={chartData}
          groupByDate={groupByDate}
        />
      </>
    );
  }

  return <></>;
};

const CompetitionCard = ({ card }: { card: IHomeCompetitionCard }) => {
  const parsedParams = useMemo(() => {
    try {
      return JSON.parse(card.cardParams);
    } catch {
      return {};
    }
  }, [card]);

  if (card.cardType === HomeCompetitionCardType.ViperChampionshipFinal) {
    return <ChampionShipFinal isHome={true} />;
  }

  if (card.cardType === HomeCompetitionCardType.ViperMyPerformance) {
    return <ViperMyPerformance isHome={true} />;
  }

  if (card.cardType === HomeCompetitionCardType.ViperBrackets) {
    return <ViperBracketsCard division={parsedParams.division} />;
  }

  if (card.cardType === HomeCompetitionCardType.Viper7WeekSeries) {
    return (
      <ViperMatchDataTableCard
        match={parsedParams.match}
        division={parsedParams.division}
      />
    );
  }

  if (card.cardType === HomeCompetitionCardType.TheCupMyMatchUp) {
    return (
      <MyMatchUp
        isHome={true}
        division={parsedParams.division}
        card_name={card.cardName}
      />
    );
  }

  if (card.cardType === HomeCompetitionCardType.TheCupOtherMatchUps) {
    return (
      <OtherMatchUp
        isHome={true}
        division={parsedParams.division}
        card_name={card.cardName}
      />
    );
  }

  if (card.cardType === HomeCompetitionCardType.TheCupSeeding) {
    return <TheCupSeeding isHome={true} type={parsedParams.type} />;
  }

  if (card.cardType === HomeCompetitionCardType.TheCupBrackets) {
    return (
      <TheCupBracketsCard
        division={parsedParams.division}
        card_name={card.cardName}
      />
    );
  }

  return <></>;
};

const ProfileCard = ({ card }: { card: IHomeProfileCard }) => {
  const parsedParams = useMemo(() => {
    try {
      return JSON.parse(card.cardParams);
    } catch {
      return {};
    }
  }, [card]);

  if (card.cardType === HomeProfileCardType.PersonalStats) {
    return (
      <PersonalStats
        cardId={card.cardName + card.cardParams}
        category={parsedParams.category}
        profileQuery={parsedParams.profileQuery}
      />
    );
  }

  if (card.cardType === HomeProfileCardType.RankBoard) {
    return (
      <RepBoard
        cardId={card.cardName + card.cardParams}
        category={parsedParams.category}
        profileQuery={parsedParams.profileQuery}
        rankBoardQuery={parsedParams.rankBoardQuery}
        orderBy={parsedParams.orderBy}
        orderDir={parsedParams.orderDir}
      />
    );
  }

  return <></>;
};

const HomeCardsSection = ({
  cards,
  sheetsData: { data: reportData },
  sheetsInfo,
  photos,
  isLoading,
  openPopup,
}: HomeCardsSectionProps) => {
  const navigate = useNavigate();

  const reportDataMap = useMemo(() => {
    const data: { [key: string]: { reportData: any; sheetInfo: any } } = {};
    if (
      !isLoading &&
      reportData.length === sheetsInfo.length &&
      reportData.length ===
        cards.filter((card) => card.sheetCategory === SheetCategory.Report)
          .length
    ) {
      let cnt = 0;
      for (let i = 0; i < cards.length; i++) {
        if (cards[i].sheetCategory === SheetCategory.Report) {
          data[i.toString()] = {
            reportData: reportData[cnt],
            sheetInfo: sheetsInfo[cnt],
          };
          cnt++;
        }
      }
    }
    return data;
  }, [reportData, cards, isLoading, sheetsInfo]);

  return (
    <div className="flex flex-col gap-4">
      {!isLoading &&
      reportData.length === sheetsInfo.length &&
      reportData.length ===
        cards.filter((card) => card.sheetCategory === SheetCategory.Report)
          .length ? (
        cards.map((card, index) => {
          if (card.sheetCategory === SheetCategory.Report)
            return (
              <div
                className="flex flex-col items-start gap-[15px] mt-m"
                key={index}
              >
                <div className="flex flex-col justify-between	 items-center gap-[10px] self-stretch mt-[6px]">
                  <div className="flex justify-between items-center w-full">
                    <h2 className="text-primaryText text-base not-italic font-bold leading-[120%] w-full">
                      {card.reportName} - {card.reportFilterViewName}
                    </h2>

                    <button
                      className="flex justify-center items-center px-m py-sm"
                      onClick={openPopup}
                    >
                      <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
                        Edit
                      </span>
                    </button>
                  </div>

                  <ReportSheet
                    sheetInfo={reportDataMap[index.toString()].sheetInfo}
                    reportSheet={card}
                    reportData={reportDataMap[index.toString()].reportData}
                    photos={photos}
                    isLoading={isLoading}
                  />
                </div>

                <Button
                  activeColor="active:bg-primary-400"
                  onClick={() => {
                    navigate(
                      `/report?alias=${card.reportAlias}&view=${card.reportViewName}`
                    );
                  }}
                >
                  <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
                    View Full Report
                  </span>
                </Button>
              </div>
            );
          else if (card.sheetCategory === SheetCategory.Competition) {
            return (
              <div
                className="flex flex-col items-start gap-[15px] mt-m"
                key={index}
              >
                <div className="flex flex-col justify-between	 items-center gap-[10px] self-stretch mt-[6px]">
                  <div className="flex justify-between items-center w-full">
                    <h2 className="text-primaryText text-base not-italic font-bold leading-[120%] w-full">
                      {card.cardName}
                    </h2>

                    <button
                      className="flex justify-center items-center px-m py-sm"
                      onClick={openPopup}
                    >
                      <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
                        Edit
                      </span>
                    </button>
                  </div>

                  <CompetitionCard card={card} />
                </div>
              </div>
            );
          } else {
            return (
              <div
                className="flex flex-col items-start gap-[15px] mt-m"
                key={index}
              >
                <div className="flex flex-col justify-between	 items-center gap-[10px] self-stretch mt-[6px]">
                  <div className="flex justify-between items-center w-full">
                    <h2 className="text-primaryText text-base not-italic font-bold leading-[120%] w-full">
                      {card.cardName}
                    </h2>

                    <button
                      className="flex justify-center items-center px-m py-sm"
                      onClick={openPopup}
                    >
                      <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
                        Edit
                      </span>
                    </button>
                  </div>

                  <ProfileCard card={card} />
                </div>
              </div>
            );
          }
        })
      ) : (
        <div className="m-auto flex items-center justify-between mt-10">
          <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default HomeCardsSection;
